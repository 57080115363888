var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-content kt-grid__item kt-grid__item--fluid"},[_c('base-header',{attrs:{"base-title":"Runner"}}),_c('div',{staticClass:"kt-portlet kt-portlet--mobile"},[(!_vm.isForm && !_vm.isFormSave && !_vm.isQrCodeScan && !_vm.isBooking)?_c('div',{staticClass:"kt-portlet__body"},[_c('div',[_c('div',{staticClass:"row justify-content-md-center align-items-center",staticStyle:{"height":"85vh"}},[_c('div',{staticClass:"col-md-auto"},[(true)?_c('button',{staticClass:"btn btn-lg btn-default btn-boldest d-block w-100 mb-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.setQrCodeScan(true)}}},[_vm._v(" SCAN QR CODE ")]):_c('label',{staticClass:"btn btn-lg btn-default btn-boldest d-block w-100 mb-3"},[_vm._v(" SCAN QR CODE "),_c('qrcode-capture',{attrs:{"id":"qr-code-scan","capture":_vm.selected.value},on:{"decode":_vm.onDecode}})],1),(
                                _vm.checkPermission(
                                    'DriveThruResource.POST.CreateBooking'
                                )
                            )?_c('button',{staticClass:"btn btn-lg btn-default btn-boldest d-block w-100 mb-3",attrs:{"type":"button"},on:{"click":_vm.showModal}},[_vm._v(" CREATE BOOKING ")]):_vm._e(),(
                                _vm.checkPermission(
                                    'DriveThruResource.GET.TableBooking'
                                )
                            )?_c('button',{staticClass:"btn btn-lg btn-default btn-boldest d-block w-100 mb-3",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toBooking()}}},[_vm._v(" SEE ALL BOOKING ")]):_vm._e()])]),(_vm.isModalVisible)?_c('form-modal'):_vm._e()],1)]):(_vm.isForm && !_vm.isFormSave && !_vm.isQrCodeScan && !_vm.isBooking)?_c('div',{staticStyle:{"overflow":"none"}},[_c('form-booking')],1):(!_vm.isForm && !_vm.isFormSave && !_vm.isQrCodeScan && _vm.isBooking)?_c('div',{staticStyle:{"overflow":"none"}},[_c('booking',{attrs:{"isFo":true}})],1):(!_vm.isForm && _vm.isFormSave && !_vm.isQrCodeScan && !_vm.isBooking)?_c('div',[_c('booking-confirmation')],1):(_vm.isQrCodeScan && !_vm.isForm && !_vm.isFormSave && !_vm.isBooking)?_c('div',[_c('qr-code-scan')],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }