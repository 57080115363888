<template>
    <div class="kt-content kt-grid__item kt-grid__item--fluid">
        <base-header base-title="Runner"></base-header>
        <div class="kt-portlet kt-portlet--mobile">
            <div
                v-if="!isForm && !isFormSave && !isQrCodeScan && !isBooking"
                class="kt-portlet__body"
            >
                <div>
                    <div
                        class="row justify-content-md-center align-items-center"
                        style="height:85vh"
                    >
                        <div class="col-md-auto">
                            <button
                                v-if="true"
                                @click="setQrCodeScan(true)"
                                type="button"
                                class="btn btn-lg btn-default btn-boldest d-block w-100 mb-3"
                            >
                                SCAN QR CODE
                            </button>
                            <label
                                v-else
                                class="btn btn-lg btn-default btn-boldest d-block w-100 mb-3"
                            >
                                SCAN QR CODE
                                <qrcode-capture
                                    id="qr-code-scan"
                                    @decode="onDecode"
                                    :capture="selected.value"
                                >
                                </qrcode-capture>
                            </label>
                            <button
                                v-if="
                                    checkPermission(
                                        'DriveThruResource.POST.CreateBooking'
                                    )
                                "
                                @click="showModal"
                                type="button"
                                class="btn btn-lg btn-default btn-boldest d-block w-100 mb-3"
                            >
                                CREATE BOOKING
                            </button>
                            <button
                                v-if="
                                    checkPermission(
                                        'DriveThruResource.GET.TableBooking'
                                    )
                                "
                                @click.prevent="toBooking()"
                                type="button"
                                class="btn btn-lg btn-default btn-boldest d-block w-100 mb-3"
                            >
                                SEE ALL BOOKING
                            </button>
                        </div>
                    </div>
                    <form-modal v-if="isModalVisible"> </form-modal>
                </div>
            </div>
            <div
                style="overflow: none"
                v-else-if="isForm && !isFormSave && !isQrCodeScan && !isBooking"
            >
                <form-booking></form-booking>
            </div>
            <div
                style="overflow: none"
                v-else-if="!isForm && !isFormSave && !isQrCodeScan && isBooking"
            >
                <booking :isFo="true"></booking>
            </div>
            <div
                v-else-if="!isForm && isFormSave && !isQrCodeScan && !isBooking"
            >
                <booking-confirmation></booking-confirmation>
            </div>
            <div
                v-else-if="isQrCodeScan && !isForm && !isFormSave && !isBooking"
            >
                <qr-code-scan></qr-code-scan>
            </div>
        </div>
    </div>
</template>

<script>
import formModal from "./components/form-modal.vue";
import formBooking from "./create-booking/form-booking.vue";
import BookingConfirmation from "./BookingConfirmation.vue";
import BaseHeader from "../_base/BaseHeader.vue";
import Booking from "./KasirView.vue";
import qrCodeScan from "./QrCodeScanner.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import { QrcodeCapture } from "vue-qrcode-reader";
import BlockUI from "../../components/_js/BlockUI";
const blockUI = new BlockUI();
import CheckPermission from "../../services/checkPermission.service";
const checkPermission = new CheckPermission();

export default {
    name: "runner_fo",
    components: {
        formModal,
        BookingConfirmation,
        formBooking,
        qrCodeScan,
        Booking,
        QrcodeCapture,
        BaseHeader
    },
    data() {
        const options = [
            { text: "rear camera (default)", value: "environment" },
            { text: "front camera", value: "user" },
            { text: "force file dialog", value: false }
        ];
        return {
            selected: options[0],
            options
        };
    },
    computed: {
        ...mapState("DriveThruStore", {
            isModalVisible: state => state.isModalVisible,
            isForm: state => state.isForm,
            isFormSave: state => state.isFormSave,
            isQrCodeScan: state => state.isQrCodeScan,
            isBooking: state => state.isBooking
        })
    },
    methods: {
        ...mapActions("DriveThruStore", {
            getOrderId: "getOrderId",
            getQrCode: "getQrCode"
        }),
        ...mapMutations("DriveThruStore", {
            setModalVisible: "SET_MODAL_VISIBLE",
            setForm: "SET_FORM",
            setQrCodeScan: "SET_QR_CODE_SCAN",
            setIsBooking: "SET_IS_BOOKING"
        }),
        showModal() {
            this.setModalVisible(false);
            setTimeout(() => {
                this.setModalVisible(true);
            }, 50);
        },
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        closeModal() {
            this.setModalVisible(false);
        },
        toQrScan() {
            this.setQrCodeScan(true);
        },
        toBooking() {
            this.setIsBooking(true);
        },
        detectMob() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];
            return toMatch.some(toMatchItem => {
                return navigator.userAgent.match(toMatchItem);
            });
        },
        async onDecode(result) {
            const baseUrl = process.env.VUE_APP_API_URL;
            try {
                var urls = new URL(result);
                if (urls.origin == process.env.VUE_APP_API_URL) {
                    const path = urls.pathname.replace("/gatewayexternal", "");
                    const params = urls.search;
                    const validUrl = `${baseUrl}${path}${params}`;
                    //ubah base url jadi pake origin dari url yang di decode
                    try {
                        blockUI.blockPage();
                        const data = await this.getOrderId(validUrl);
                        blockUI.unblockPage();
                        this.$router.push({
                            name: "drive-thru-view",
                            params: { id: data.data.orderId }
                        });
                    } catch (error) {
                        blockUI.unblockPage();
                        console.log(error);
                    }
                } else {
                    // this.error = "QR Code Tidak Valid";
                }
            } catch (error) {
                console.log(error, "Qr Code Reader Error");
            }
        }
    }
};
</script>

<style></style>
