<template>
    <div class="d-flex justify-content-center flex-column align-items-center">
        <div style="overflow-y: auto; height: 85vh;">
            <div class="kt-portlet__head-toolbar">
                <div
                    class="kt-portlet__head-wrapper d-flex justify-content-between"
                >
                    <button
                        @click="backToForm"
                        class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                    >
                        <i style="color: white;" class="fas fa-arrow-left"></i>
                    </button>
                </div>
            </div>
            <test-card :patientForms="patientForms"></test-card>
        </div>
        <button
            @click.prevent="confirmBooking"
            class="button-bar btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
        >
            {{ totalPrice | formatPrice }}
            {{
                totalPrice
                    ? `Pilih Metode Pembayaran`
                    : "Tidak Ada Pasien Valid"
            }}
        </button>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import TestCard from "./components/TestCards.vue";

export default {
    name: "BookingConfirmation",
    components: {
        TestCard
    },
    data() {
        return {
            printOut: false,
            paymentType: "ONLINE",
            indexPrimaryPatient: null
        };
    },
    computed: {
        ...mapState("DriveThruStore", {
            patientForms: state => state.patientForms,
            validPatientForms: state => state.validPatientForms,
            primaryPatients: state => state.primaryPatients,
            totalPrice: state => state.totalPrice,
            tglKunjungan: state => state.tglKunjungan
        })
    },
    filters: {
        formatPrice(value) {
            if (!value) {
                return null;
            }
            const localePrice = value.toLocaleString("id", {
                style: "currency",
                currency: "IDR"
            });
            return `${localePrice}`;
        }
    },
    methods: {
        ...mapMutations("DriveThruStore", {
            setPatientForms: "SET_PATIENT_FORMS",
            setValidPatientForms: "SET_VALID_PATIENT_FORMS",
            filterIsDeletedForm: "FILTER_IS_DELETED_PATIENT_FORM",
            setForm: "SET_FORM",
            setIsFormSave: "SET_FORM_SAVE",
            setFormCount: "SET_FORM_COUNT"
        }),
        ...mapActions("DriveThruStore", {
            postPcr: "postPcr"
        }),
        confirmBooking() {
            const vx = this;
            this.filterIsDeletedForm();
            this.setValidPatientForms();
            const checkPrimaryPatients = _.isEmpty(this.primaryPatients);
            if (!checkPrimaryPatients) {
                Swal.fire({
                    title: "Pilih Metode Pembayaran",
                    confirmButtonText: "Online",
                    cancelButtonText: "Bayar Di Tempat",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    showCloseButton: true,
                    reverseButtons: true
                }).then(result => {
                    if (result.dismiss == "cancel") {
                        this.paymentType = "OFFLINE";
                        vx.primaryPatient();
                    } else if (result.value) {
                        vx.primaryPatient();
                    }
                });
            } else {
                Swal.fire({
                    title: "Tidak Ada Patient Yang Dapat Dipilih",
                    confirmButtonText: "Ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    showCloseButton: true,
                    reverseButtons: true
                }).then(result => {
                    if (result.value) {
                        console.log("submit");
                    } else {
                        console.log("cancel");
                    }
                });
            }
        },
        async makeData() {
            let mainObj = {
                paymentType: this.paymentType,
                tanggalKunjungan: moment(this.tglKunjungan).format(
                    "YYYY-MM-DD"
                ),
                testPemeriksaan: []
            };
            let tipe_testA = [];
            let tujuanPemeriksaanA = null;
            const stringData = JSON.stringify(this.patientForms);
            let data = [...JSON.parse(stringData)];
            data.forEach((ele, index) => {
                ele.dataPasien.noTelepon = ele.dataPasien.telp;
                ele.dataPasien.tglLahir = moment(
                    ele.dataPasien.tglLahir
                ).format("YYYY-MM-DD");
                if (ele.dataKlinis.tglAwalGejala) {
                    ele.dataKlinis.tglAwalGejala = moment(
                        ele.dataKlinis.tglAwalGejala
                    ).format("YYYY-MM-DD");
                }
                tipe_testA = ele.dataKlinis.tipe_test;
                tujuanPemeriksaanA = ele.dataKlinis.tujuanPemeriksaan;
                delete ele.dataPasien.telp;
                delete ele.dataKlinis.tujuanPemeriksaan;
                delete ele.dataKlinis.tipe_test;
                mainObj.testPemeriksaan.push({
                    patientLab: ele.dataPasien,
                    klinis: ele.dataKlinis,
                    tujuan: tujuanPemeriksaanA,
                    printOut: this.printOut,
                    primaryPatient:
                        index == this.indexPrimaryPatient ? true : false,
                    paketPemeriksaans: []
                });
                tipe_testA.forEach(el => {
                    mainObj.testPemeriksaan[index].paketPemeriksaans.push({
                        paketPemeriksaan: { idMasterPaket: el.id, id: null },
                        cancel: false
                    });
                });
            });
            this.postPcr({
                data: mainObj,
                router: this.$router
            });
        },
        async isPrintOUt() {
            Swal.fire({
                title:
                    "Apakah Pasien Ingin Menerima Hasil Dalam Bentuk Hard Copy?",
                confirmButtonText: "TIDAK",
                showCancelButton: true,
                cancelButtonText: "IYA",
                reverseButtons: true,
                allowOutsideClick: false,
                showCloseButton: true
            }).then(result => {
                // console.log(result);
                if (result.dismiss == "cancel") {
                    this.printOut = true;
                }
                if (result.dismiss == "cancel" || result.value) {
                    this.makeData();
                }
            });
        },
        async primaryPatient() {
            if (Object.keys(this.primaryPatients).length == 1) {
                this.indexPrimaryPatient = 0;
                this.isPrintOUt();
            } else {
                Swal.fire({
                    title: "Pilih Patient Penerima Link Pembayaran",
                    input: "select",
                    inputOptions: this.primaryPatients,
                    inputPlaceholder: "Pilih Pasien",
                    showCancelButton: true,
                    reverseButtons: true,
                    allowOutsideClick: false,
                    inputValidator: value => {
                        if (!value) {
                            return "Pilih Salah Satu Pasien";
                        }
                    }
                }).then(result => {
                    if (result.dismiss != "cancel") {
                        this.indexPrimaryPatient = result.value;
                        this.isPrintOUt();
                    }
                });
            }
        },
        backToForm() {
            this.setForm(true);
            this.setIsFormSave(false);
        }
    },
    created() {
        if (localStorage.getItem("bulkForm")) {
            this.setPatientForms(JSON.parse(localStorage.getItem("bulkForm")));
        }
        this.filterIsDeletedForm();
        this.setValidPatientForms();
    }
};
</script>

<style></style>
