export default function Fee() {
    return {
        nik: null, //
        namaDepan: null, //
        namaBelakang: null, //
        telp: null, //
        email: null, //
        jenisKelamin: null, //jenis_kelamin
        tglLahir: null, //
        alamat: null,
        rtRw: null, // rt_tw
        kelurahan: null, //
        kecamatan: null, //
        kabupaten: null, //
        kewarganegaraan: null, //
        negara: null, //
        linkKtp: null, //b64
        imgKtp: null, // img filename,
        linkFoto: null, //b64
        imgFoto: null, // img filename,
        rt: null,
        rw: null
    };
}
