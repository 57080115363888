var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow-y":"auto","heigth":"85vh","position":"relative"}},_vm._l((_vm.patientForms),function(form,index){return _c('div',{key:index,class:form.isDeleted
                ? 'card text-white mb-3 test-cards jello-horizontal'
                : 'card text-white mb-3 test-cards',style:(form.isDeleted
                ? 'background-color: #475178 !important;'
                : 'animation: 0s ease 0s 1 normal running none'),attrs:{"id":`card${index}`}},[_c('div',{staticClass:"card-body flex-column"},[_c('div',[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(`${ form.dataPasien.namaDepan ? form.dataPasien.namaDepan : "" } ${ form.dataPasien.namaBelakang ? form.dataPasien.namaBelakang : "" }`)+" ")]),_c('div',{staticClass:"d-flex"},[_vm._l((form['dataKlinis']
                            .tipe_test),function(test,index){return _c('div',{key:index,staticClass:"card-text"},[(index < 2)?_c('label',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active',style:(form.isDeleted
                                    ? `text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100px;background-color: #613e4a;border-color: #613e4a;color: white;`
                                    : `text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100px;`),attrs:{"title":test.nama}},[_vm._v(" "+_vm._s(test.nama)+" ")]):(
                                form['dataKlinis'].tipe_test.length == 0
                            )?_c('label',{class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning',staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","width":"100px"}},[_vm._v(" "+_vm._s("KOSONG")+" ")]):_vm._e()])}),(form['dataKlinis'].tipe_test.length > 2)?_c('label',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning',staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","width":"100px"},attrs:{"title":_vm.moreTestNames(form.dataKlinis.tipe_test)}},[_vm._v(" + "+_vm._s(form["dataKlinis"].tipe_test.length - 2)+"... ")]):_vm._e()],2),_c('label',{staticClass:"btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active",style:(form.isDeleted
                            ? 'background-color: #897140;border-color: #897140;'
                            : '')},[_vm._v(" "+_vm._s(_vm._f("totalPriceTest")(form.dataKlinis.tipe_test))+" ")]),_c('p',{staticClass:"card-text"},[_vm._v(" NIK: "+_vm._s(form[`dataPasien`].nik ? form[`dataPasien`].nik : "NIK Kosong")+" ")]),_c('p',{staticClass:"card-text"},[_vm._v(" No. HP: "+_vm._s(form[`dataPasien`].telp ? form[`dataPasien`].telp : "No.Telp Kosong")+" ")]),_c('p',{staticClass:"card-text"},[_vm._v(" Tgl Lahir: "+_vm._s(form[`dataPasien`].tglLahir ? form[`dataPasien`].tglLahir : "tglLahir Kosong")+" ")]),_c('p',{staticClass:"card-text"},[_vm._v(" Email: "+_vm._s(form[`dataPasien`].email ? form[`dataPasien`].email : "Email Kosong")+" ")])]),(!form.isDeleted)?_c('button',{staticClass:"close cards-delete",staticStyle:{"border":"2px solid #5578eb","border-radius":"5px","padding":"5px","background-color":"#5578eb","min-width":"144px","max-width":"144px"},attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){$event.preventDefault();return _vm.disableForm(index)}}},[_vm._v(" Non Aktifkan ")]):_c('button',{staticClass:"close cards-delete",staticStyle:{"border":"2px solid #5578eb","border-radius":"5px","padding":"5px","background-color":"#5578eb","min-width":"144px","max-width":"144px"},attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){$event.preventDefault();return _vm.restoreForm(index)}}},[_vm._v(" Aktifkan ")])]),(form.isDeleted)?_c('div',{staticStyle:{"position":"absolute","transform":"rotate(45deg)","border-top":"white 2px solid","width":"100%","top":"50%"}}):_vm._e(),(form.isDeleted)?_c('div',{staticStyle:{"position":"absolute","transform":"rotate(-45deg)","border-top":"white 2px solid","width":"100%","top":"50%"}}):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }