<template>
    <div style="overflow-y: auto; heigth: 85vh;position:relative;">
        <div
            :class="
                form.isDeleted
                    ? 'card text-white mb-3 test-cards jello-horizontal'
                    : 'card text-white mb-3 test-cards'
            "
            :style="
                form.isDeleted
                    ? 'background-color: #475178 !important;'
                    : 'animation: 0s ease 0s 1 normal running none'
            "
            v-for="(form, index) in patientForms"
            :key="index"
            :id="`card${index}`"
        >
            <!-- animation: 0s ease 0s 1 normal running none -->
            <div
                class="
                    card-body flex-column
                "
            >
                <div>
                    <h5 class="card-title">
                        {{
                            `${
                                form.dataPasien.namaDepan
                                    ? form.dataPasien.namaDepan
                                    : ""
                            } ${
                                form.dataPasien.namaBelakang
                                    ? form.dataPasien.namaBelakang
                                    : ""
                            }`
                        }}
                    </h5>
                    <div class="d-flex">
                        <div
                            v-for="(test, index) in form['dataKlinis']
                                .tipe_test"
                            :key="index"
                            class="card-text"
                        >
                            <label
                                v-if="index < 2"
                                :class="
                                    'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active'
                                "
                                v-b-tooltip.hover.top
                                :title="test.nama"
                                :style="
                                    form.isDeleted
                                        ? `text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100px;background-color: #613e4a;border-color: #613e4a;color: white;`
                                        : `text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100px;`
                                "
                            >
                                {{ test.nama }}
                            </label>
                            <label
                                v-else-if="
                                    form['dataKlinis'].tipe_test.length == 0
                                "
                                :class="
                                    'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning'
                                "
                                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100px;"
                            >
                                {{ "KOSONG" }}
                            </label>
                        </div>
                        <label
                            v-b-tooltip.hover.top
                            :title="moreTestNames(form.dataKlinis.tipe_test)"
                            v-if="form['dataKlinis'].tipe_test.length > 2"
                            :class="
                                'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning'
                            "
                            style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100px;"
                        >
                            + {{ form["dataKlinis"].tipe_test.length - 2 }}...
                        </label>
                    </div>
                    <label
                        :style="
                            form.isDeleted
                                ? 'background-color: #897140;border-color: #897140;'
                                : ''
                        "
                        class="btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active"
                    >
                        {{ form.dataKlinis.tipe_test | totalPriceTest }}
                    </label>
                    <p class="card-text">
                        NIK:
                        {{
                            form[`dataPasien`].nik
                                ? form[`dataPasien`].nik
                                : "NIK Kosong"
                        }}
                    </p>
                    <p class="card-text">
                        No. HP:
                        {{
                            form[`dataPasien`].telp
                                ? form[`dataPasien`].telp
                                : "No.Telp Kosong"
                        }}
                    </p>
                    <p class="card-text">
                        Tgl Lahir:
                        {{
                            form[`dataPasien`].tglLahir
                                ? form[`dataPasien`].tglLahir
                                : "tglLahir Kosong"
                        }}
                    </p>
                    <p class="card-text">
                        Email:
                        {{
                            form[`dataPasien`].email
                                ? form[`dataPasien`].email
                                : "Email Kosong"
                        }}
                    </p>
                </div>
                <button
                    v-if="!form.isDeleted"
                    @click.prevent="disableForm(index)"
                    type="button"
                    class="close cards-delete"
                    data-dismiss="modal"
                    aria-label="Close"
                    style="border: 2px solid #5578eb;
    border-radius: 5px;
    padding: 5px;
    background-color: #5578eb; min-width: 144px;max-width: 144px;"
                >
                    Non Aktifkan
                </button>
                <button
                    v-else
                    @click.prevent="restoreForm(index)"
                    type="button"
                    class="close cards-delete"
                    data-dismiss="modal"
                    aria-label="Close"
                    style="border: 2px solid #5578eb;
    border-radius: 5px;
    padding: 5px;
    background-color: #5578eb;    min-width: 144px;max-width: 144px;"
                >
                    Aktifkan
                </button>
            </div>
            <div
                style="position: absolute;
    transform: rotate(45deg);
    border-top: white 2px solid;
    width: 100%;
    top: 50%;"
                v-if="form.isDeleted"
            ></div>
            <div
                style="position: absolute;
    transform: rotate(-45deg);
    border-top: white 2px solid;
    width: 100%;
    top: 50%;"
                v-if="form.isDeleted"
            ></div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
    name: "TestCard",
    data() {
        return {
            disabledForms: [],
            allForms: []
        };
    },
    computed: {
        ...mapState("DriveThruStore", {
            patientForms: state => state.patientForms
        })
    },
    methods: {
        ...mapMutations("DriveThruStore", {
            deletePatientForm: "DELETE_PATIENT_FORM",
            restorePatientForm: "RESTORE_PATIENT_FORM",
            setValidPatientForms: "SET_VALID_PATIENT_FORMS",
            filterIsDeletedForm: "FILTER_IS_DELETED_PATIENT_FORM"
        }),
        disableForm(pos) {
            this.deletePatientForm(pos);
            localStorage.setItem("bulkForm", JSON.stringify(this.patientForms));
            this.filterIsDeletedForm();
            this.setValidPatientForms();
        },
        restoreForm(pos) {
            this.restorePatientForm(pos);
            localStorage.setItem("bulkForm", JSON.stringify(this.patientForms));
            this.filterIsDeletedForm();
            this.setValidPatientForms();
        },
        moreTestNames(array) {
            let result = ``;
            array.forEach((ele, index) => {
                if (index >= 2) {
                    result += `${ele.nama} | `;
                }
            });
            return result;
        }
    },
    filters: {
        totalPriceTest(value) {
            let total = 0;
            value.forEach(test => {
                total += test.price;
            });
            return total.toLocaleString("id", {
                style: "currency",
                currency: "IDR"
            });
        }
    }
};
</script>

<style></style>
