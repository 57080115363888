var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"sticky-buttons"},[_c('button',{staticClass:"btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper",on:{"click":_vm.backToMenu}},[_c('i',{staticClass:"fas fa-arrow-left",staticStyle:{"color":"white"}})]),_c('button',{staticClass:"btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper",on:{"click":_vm.multipleForm}},[_vm._v(" "+_vm._s(`${_vm.formPage} / ${_vm.formCount}`)+" ")])]),(_vm.formCount > 0)?_c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"flex-direction":"column","margin-top":"3rem"}},[_c('form',{staticClass:"create-form",attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"accordion",staticStyle:{"padding":"1.25rem"},attrs:{"role":"tablist"}},[_c('label',{staticClass:"col-lg-4 btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper",attrs:{"id":"ktp-label","for":"ktp-ocr"}},[_vm._v(" ktp ocr "),_c('input',{attrs:{"type":"file","name":"","id":"ktp-ocr","capture":"camera","autocomplete":"off","accept":"image/png, image/jpeg"},on:{"change":_vm.setImage}})]),(_vm.fileType && _vm.imgSrc && _vm.isCropperModal)?_c('cropper-modal',{attrs:{"imgSrc":_vm.imgSrc,"fileType":_vm.fileType,"fileName":_vm.fileName,"formPage":_vm.formPage,"changedValue":_vm.emittedData},on:{"update:changedValue":function($event){_vm.emittedData=$event},"update:changed-value":function($event){_vm.emittedData=$event}}}):_vm._e(),(_vm.bulkForm[_vm.formPage - 1][`dataPasien`].linkKtp)?_c('div',{staticClass:"form-group row"},[_c('label',{class:_vm.ocrLoading
                                    ? 'col-lg-4 col-form-label shimmer'
                                    : 'col-lg-4 col-form-label'},[_vm._v(" "+_vm._s(_vm.ocrLoading ? "SCANNING FOTO KTP" : "Foto Ktp Tersimpan")+" "),(!_vm.ocrLoading)?_c('span',[_c('i',{staticClass:"fas fa-check",staticStyle:{"color":"green"}})]):_vm._e()]),(
                                _vm.bulkForm[_vm.formPage - 1][`dataPasien`].linkKtp
                            )?_c('img',{staticClass:"col-lg-6 ktp-image-preview",style:(_vm.ocrLoading ? 'filter: grayscale(100%);' : ''),attrs:{"src":`data:image/jpeg;base64,${
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .linkKtp
                                }`,"alt":"Cropped Image"}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('vue-date-picker',{attrs:{"format":_vm.dateFormat,"scope":'form-2',"validation":'required',"validationName":'Tanggal Kunjungan',"name":'tanggalKunjungan',"clearable":false,"editable":false,"disabled-date":_vm.disabledDateFunc,"eventCalendarChange":_vm.eventCalendarChange},on:{"change":function($event){return _vm.tglKunjunganMe(
                                        _vm.tglKunjungan,
                                        'kunjungan'
                                    )}},model:{value:(_vm.tglKunjungan),callback:function ($$v) {_vm.tglKunjungan=$$v},expression:"tglKunjungan"}}),(_vm.isYesterdayDate)?_c('small',{staticClass:"form-text text-danger",attrs:{"id":"yesterDate"}},[_vm._v(" Tanggal minimal hari ini")]):_vm._e()],1)]),_c('div',{staticClass:"form-group row"},[_vm._m(1),_c('div',{staticClass:"col-lg-6",staticStyle:{"margin-left":"20px"}},[_c('label',{staticClass:"kt-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kewarganegaraan
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .kewarganegaraan\n                                    "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"radio","name":"kewarganegaraan","value":"Indonesia"},domProps:{"checked":_vm._q(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kewarganegaraan
                                    ,"Indonesia")},on:{"click":_vm.clearSelectedNation,"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "kewarganegaraan", "Indonesia")}}}),_vm._v(" WNI "),_c('span')]),_c('label',{staticClass:"kt-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kewarganegaraan
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .kewarganegaraan\n                                    "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"radio","name":"kewarganegaraan","value":"WNA"},domProps:{"checked":_vm._q(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kewarganegaraan
                                    ,"WNA")},on:{"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "kewarganegaraan", "WNA")}}}),_vm._v(" WNA "),_c('span')])])]),(
                            _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                .kewarganegaraan == 'WNA'
                        )?_c('div',{staticClass:"form-group row"},[_vm._m(2),_c('div',{staticClass:"col-lg-6"},[_c('multiselect',{attrs:{"allow-empty":false,"id":"negara","searchable":true,"close-on-select":true,"openDirection":"bottom","options":_vm.nations,"label":"name"},model:{value:(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .negara
                                ),callback:function ($$v) {_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        , "negara", $$v)},expression:"\n                                    bulkForm[formPage - 1][`dataPasien`]\n                                        .negara\n                                "}})],1)]):_vm._e(),_c('div',{staticClass:"form-group row"},[_vm._m(3),_c('div',{staticClass:"col-lg-6"},[((_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == 'Indonesia' ||
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == null
                                        ? 'number'
                                        : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                ),expression:"\n                                    bulkForm[formPage - 1][`dataPasien`].nik\n                                "}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has('ssn')
                                },attrs:{"autocomplete":"off","title":"NIK","pattern":"^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\\d{2}\\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\\d{2}\\d{4}$","maxlength":_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == 'Indonesia' ||
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == null
                                        ? '16'
                                        : '',"placeholder":"NIK KTP Pasien","data-vv-as":"ssn","data-vv-scope":"form","id":"nik","name":"ssn","type":"checkbox"},domProps:{"checked":Array.isArray(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                )?_vm._i(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                ,null)>-1:(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                )},on:{"keyup":_vm.inputSsn,"paste":_vm.inputSsn,"change":function($event){var $$a=
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`], "nik", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`], "nik", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`], "nik", $$c)}}}}):((_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == 'Indonesia' ||
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == null
                                        ? 'number'
                                        : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                ),expression:"\n                                    bulkForm[formPage - 1][`dataPasien`].nik\n                                "}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has('ssn')
                                },attrs:{"autocomplete":"off","title":"NIK","pattern":"^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\\d{2}\\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\\d{2}\\d{4}$","maxlength":_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == 'Indonesia' ||
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == null
                                        ? '16'
                                        : '',"placeholder":"NIK KTP Pasien","data-vv-as":"ssn","data-vv-scope":"form","id":"nik","name":"ssn","type":"radio"},domProps:{"checked":_vm._q(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                ,null)},on:{"keyup":_vm.inputSsn,"paste":_vm.inputSsn,"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`], "nik", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                ),expression:"\n                                    bulkForm[formPage - 1][`dataPasien`].nik\n                                "}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has('ssn')
                                },attrs:{"autocomplete":"off","title":"NIK","pattern":"^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\\d{2}\\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\\d{2}\\d{4}$","maxlength":_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == 'Indonesia' ||
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == null
                                        ? '16'
                                        : '',"placeholder":"NIK KTP Pasien","data-vv-as":"ssn","data-vv-scope":"form","id":"nik","name":"ssn","type":_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == 'Indonesia' ||
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .kewarganegaraan == null
                                        ? 'number'
                                        : 'text'},domProps:{"value":(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`].nik
                                )},on:{"keyup":_vm.inputSsn,"paste":_vm.inputSsn,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`], "nik", $event.target.value)}}}),(!_vm.isNikValid)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"inline"}},[_vm._v(" NIK tidak valid ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("common.phoneNo"))+" :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.bulkForm[_vm.formPage - 1].dataPasien.telp
                                ),expression:"\n                                    bulkForm[formPage - 1].dataPasien.telp\n                                "}],staticClass:"form-control",attrs:{"name":"telepon","id":"telepon","autocomplete":"telepon","type":"number","placeholder":"No. Telepon Pasien"},domProps:{"value":(
                                    _vm.bulkForm[_vm.formPage - 1].dataPasien.telp
                                )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1].dataPasien, "telp", $event.target.value)},_vm.telepon]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNoTelpValid),expression:"!isNoTelpValid"}],staticStyle:{"width":"100%","margin-top":"0.25rem","font-size":"80%","color":"#fd397a"}},[_vm._v(" No Telepon Tidak Valid ")])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("common.email"))+" :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .email
                                ),expression:"\n                                    bulkForm[formPage - 1][`dataPasien`]\n                                        .email\n                                "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"email","name":"email","placeholder":"Email Pasien"},domProps:{"value":(
                                    _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        .email
                                )},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                        , "email", $event.target.value)},_vm.emailInput]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmailValid),expression:"!isEmailValid"}],staticStyle:{"width":"100%","margin-top":"0.25rem","font-size":"80%","color":"#fd397a"}},[_vm._v(" Email Tidak Valid ")])])]),_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.data-pasien",modifiers:{"data-pasien":true}}],staticClass:"m-1",attrs:{"pressed":_vm.buttonToggle['data-pasien'],"block":""},on:{"click":function($event){return _vm.toggleTab('data-pasien')}}},[_vm._v(" Data Pasien")])],1),_c('b-collapse',{attrs:{"role":"tabpanel","visible":"","id":"data-pasien"}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("common.firstName"))+":"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .namaDepan
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .namaDepan\n                                    "}],staticClass:"form-control",attrs:{"autocomplete":"patientFirstName","type":"text","placeholder":"Nama Depan Pasien","name":"patientFirstName","id":"patientFirstName"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .namaDepan
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "namaDepan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("common.lastName"))+": ")]),_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .namaBelakang
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .namaBelakang\n                                    "}],staticClass:"form-control",attrs:{"autocomplete":"patientLastName","type":"text","placeholder":"Nama Belakang Pasien","name":"patientLastName","id":"patientLastName"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .namaBelakang
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "namaBelakang", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("common.tanggalLahir"))+": "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('vue-date-picker',{attrs:{"scope":'form-2',"validation":'required',"validationName":'Tanggal Lahir',"name":'tanggalLahir',"clearable":false,"editable":false,"disabled-date":'notAfterToday',"format":_vm.dateFormat},model:{value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .tglLahir
                                    ),callback:function ($$v) {_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "tglLahir", $$v)},expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .tglLahir\n                                    "}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("common.gender"))+":"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"kt-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataPasien`
                                            ].jenisKelamin
                                        ),expression:"\n                                            bulkForm[formPage - 1][\n                                                `dataPasien`\n                                            ].jenisKelamin\n                                        "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"radio","name":"jenis_kelamin","value":"M"},domProps:{"checked":_vm._q(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataPasien`
                                            ].jenisKelamin
                                        ,"M")},on:{"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][
                                                `dataPasien`
                                            ], "jenisKelamin", "M")}}}),_vm._v(" Laki-Laki "),_c('span')]),_c('label',{staticClass:"kt-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataPasien`
                                            ].jenisKelamin
                                        ),expression:"\n                                            bulkForm[formPage - 1][\n                                                `dataPasien`\n                                            ].jenisKelamin\n                                        "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"radio","name":"jenis_kelamin","value":"F"},domProps:{"checked":_vm._q(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataPasien`
                                            ].jenisKelamin
                                        ,"F")},on:{"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][
                                                `dataPasien`
                                            ], "jenisKelamin", "F")}}}),_vm._v(" Perempuan "),_c('span')])])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Alamat :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .alamat
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .alamat\n                                    "}],staticClass:"form-control",attrs:{"rows":"4","placeholder":"Alamat Pasien","type":"text","name":"alamat","id":"alamat","autocomplete":"alamat"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .alamat
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "alamat", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("RT/RW :")]),_c('div',{staticClass:"col-lg-6",attrs:{"id":"rt-rw-form"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .rt
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .rt\n                                    "}],ref:"input-0",staticClass:"form-control rt-rw",staticStyle:{"width":"70px","display":"inline"},attrs:{"id":"0","maxlength":"3","type":"number","data-index":"0"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .rt
                                    )},on:{"change":_vm.rtChange,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "rt", $event.target.value)}}}),_vm._v(" / "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .rw
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .rw\n                                    "}],ref:"input-1",staticClass:"form-control rt-rw",staticStyle:{"width":"70px","display":"inline"},attrs:{"id":"1","data-index":"1","maxlength":"3","type":"number"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .rw
                                    )},on:{"change":_vm.rwChange,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "rw", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("kelurahan :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kelurahan
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .kelurahan\n                                    "}],staticClass:"form-control",attrs:{"autocomplete":"kelurahan","type":"text","name":"kelurahan","id":"kelurahan"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kelurahan
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "kelurahan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Kecamatan :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kecamatan
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .kecamatan\n                                    "}],staticClass:"form-control",attrs:{"autocomplete":"kecamatan","type":"text","name":"kecamatan","id":"kecamatan"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kecamatan
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "kecamatan", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Kabupaten :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kabupaten
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataPasien`]\n                                            .kabupaten\n                                    "}],staticClass:"form-control",attrs:{"autocomplete":"kabupaten","type":"text","name":"kabupaten","id":"kabupaten"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            .kabupaten
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataPasien`]
                                            , "kabupaten", $event.target.value)}}})])])]),_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.data-klinis",modifiers:{"data-klinis":true}}],staticClass:"m-1",attrs:{"pressed":_vm.buttonToggle['data-klinis'],"block":""},on:{"click":function($event){return _vm.toggleTab('data-klinis')}}},[_vm._v("Data Klinis")])],1),_c('b-collapse',{staticStyle:{"margin-bottom":"50px"},attrs:{"role":"tabpanel","visible":"","id":"data-klinis"}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label",attrs:{"for":"tipe-test"}},[_vm._v(" Tipe Test :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('multiselect',{attrs:{"track-by":"nama","label":"nama","allow-empty":false,"multiple":true,"openDirection":"bottom","searchable":false,"close-on-select":true,"options":_vm.optionsPaket,"custom-label":_vm.paketPrice,"placeholder":"Pilih Tipe Test"},model:{value:(
                                        _vm.bulkForm[_vm.formPage - 1].dataKlinis
                                            .tipe_test
                                    ),callback:function ($$v) {_vm.$set(_vm.bulkForm[_vm.formPage - 1].dataKlinis
                                            , "tipe_test", $$v)},expression:"\n                                        bulkForm[formPage - 1].dataKlinis\n                                            .tipe_test\n                                    "}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Tujuan Pemeriksaan :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('multiselect',{attrs:{"openDirection":"bottom","placeholder":"Pilih Tujuan Pemeriksaan","allow-empty":false,"searchable":false,"close-on-select":true,"options":_vm.tujuanPemeriksaan,"custom-label":_vm.customLabel},model:{value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .tujuanPemeriksaan
                                    ),callback:function ($$v) {_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            , "tujuanPemeriksaan", $$v)},expression:"\n                                        bulkForm[formPage - 1][`dataKlinis`]\n                                            .tujuanPemeriksaan\n                                    "}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Riwayat Kontak :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('multiselect',{attrs:{"openDirection":"bottom","placeholder":"Pilih Riwayat Kontak","searchable":false,"close-on-select":true,"options":_vm.riwayatKontakOptions,"custom-label":_vm.customLabel},model:{value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .riwayatKontak
                                    ),callback:function ($$v) {_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            , "riwayatKontak", $$v)},expression:"\n                                        bulkForm[formPage - 1][`dataKlinis`]\n                                            .riwayatKontak\n                                    "}})],1)]),(
                                _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                    .riwayatKontak == 'ADA'
                            )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Riwayat Kontak Detail :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .riwayatKontakDetail
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataKlinis`]\n                                            .riwayatKontakDetail\n                                    "}],staticClass:"form-control",attrs:{"rows":"4","placeholder":"Kontak Detail Pasien","type":"text","name":"kontak_detail"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .riwayatKontakDetail
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            , "riwayatKontakDetail", $event.target.value)}}})])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Gejala :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"kt-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ].gejala
                                        ),expression:"\n                                            bulkForm[formPage - 1][\n                                                `dataKlinis`\n                                            ].gejala\n                                        "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"radio","name":"gejala"},domProps:{"value":false,"checked":_vm._q(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ].gejala
                                        ,false)},on:{"click":_vm.clearDetailGejala,"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ], "gejala", false)}}}),_vm._v(" Tidak Bergejala "),_c('span')]),_c('label',{staticClass:"kt-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ].gejala
                                        ),expression:"\n                                            bulkForm[formPage - 1][\n                                                `dataKlinis`\n                                            ].gejala\n                                        "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"radio","name":"gejala"},domProps:{"value":true,"checked":_vm._q(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ].gejala
                                        ,true)},on:{"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ], "gejala", true)}}}),_vm._v(" Bergejala "),_c('span')])])]),(
                                _vm.bulkForm[_vm.formPage - 1][`dataKlinis`].gejala
                            )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Detail Gejala :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .gejalaDetail
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataKlinis`]\n                                            .gejalaDetail\n                                    "}],staticClass:"form-control",attrs:{"rows":"4","placeholder":"Detail Gejala Pasien","type":"text","name":"gejala_detail"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .gejalaDetail
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            , "gejalaDetail", $event.target.value)}}})])]):_vm._e(),(
                                _vm.bulkForm[_vm.formPage - 1][`dataKlinis`].gejala
                            )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(" Tanggal Awal Gejala:"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('vue-date-picker',{attrs:{"format":_vm.dateFormat,"scope":'form-2',"validation":'required',"validationName":'Tanggal Kunjungan',"name":'tanggalKunjungan',"clearable":false,"editable":false,"disabled-date":'notAfterToday'},model:{value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .tglAwalGejala
                                    ),callback:function ($$v) {_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            , "tglAwalGejala", $$v)},expression:"\n                                        bulkForm[formPage - 1][`dataKlinis`]\n                                            .tglAwalGejala\n                                    "}}),(_vm.isTomorrowDate)?_c('small',{staticClass:"form-text text-danger",attrs:{"id":"passwordHelpBlock"}},[_vm._v(" Tanggal maksimal hari ini")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Memiliki Penyakit Penyerta :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"kt-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ].penyakitPenyerta
                                        ),expression:"\n                                            bulkForm[formPage - 1][\n                                                `dataKlinis`\n                                            ].penyakitPenyerta\n                                        "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"radio","name":"penyakit-penyerta"},domProps:{"value":false,"checked":_vm._q(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ].penyakitPenyerta
                                        ,false)},on:{"click":_vm.clearPenyakitDetail,"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ], "penyakitPenyerta", false)}}}),_vm._v(" Tidak Ada "),_c('span')]),_c('label',{staticClass:"kt-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ].penyakitPenyerta
                                        ),expression:"\n                                            bulkForm[formPage - 1][\n                                                `dataKlinis`\n                                            ].penyakitPenyerta\n                                        "}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"radio","name":"penyakit-penyerta"},domProps:{"value":true,"checked":_vm._q(
                                            _vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ].penyakitPenyerta
                                        ,true)},on:{"change":function($event){return _vm.$set(_vm.bulkForm[_vm.formPage - 1][
                                                `dataKlinis`
                                            ], "penyakitPenyerta", true)}}}),_vm._v(" Ada "),_c('span')])])]),(
                                _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                    .penyakitPenyerta
                            )?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Detail Penyakit Penyerta :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .penyakitPenyertaDetail
                                    ),expression:"\n                                        bulkForm[formPage - 1][`dataKlinis`]\n                                            .penyakitPenyertaDetail\n                                    "}],staticClass:"form-control",attrs:{"rows":"4","placeholder":"Detail Penyakit Peserta Pasien","type":"text","name":"penyakit_penyerta_detail"},domProps:{"value":(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .penyakitPenyertaDetail
                                    )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            , "penyakitPenyertaDetail", $event.target.value)}}})])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Riwayat Perjalanan :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('div',{staticClass:"col-lg-6"},[_c('multiselect',{attrs:{"openDirection":"bottom","allow-empty":false,"placeholder":"Riwayat Perjalanan","searchable":false,"close-on-select":true,"options":_vm.riwayatPerjalananOptions,"custom-label":_vm.customLabel},model:{value:(
                                        _vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            .riwayatPerjalanan
                                    ),callback:function ($$v) {_vm.$set(_vm.bulkForm[_vm.formPage - 1][`dataKlinis`]
                                            , "riwayatPerjalanan", $$v)},expression:"\n                                        bulkForm[formPage - 1][`dataKlinis`]\n                                            .riwayatPerjalanan\n                                    "}})],1)])]),_vm._m(4)],1),_c('button',{staticClass:"button-bar btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper",staticStyle:{"width":"100%"},on:{"click":function($event){$event.preventDefault();return _vm.saveConfirmation.apply(null, arguments)}}},[_vm._v(" Simpan ")])])])]):_c('div',[_c('h1',[_vm._v("no form")])]),(_vm.isShowRequiredModal)?_c('required-modal',{attrs:{"requiredInput":_vm.validationInput}}):_vm._e(),(_vm.isModalVisible)?_c('div',{staticClass:"modal fade",attrs:{"id":_vm.modalName,"tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalScrollableTitle","aria-hidden":"true"}},[_vm._m(5),_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-dialog-scrollable",staticStyle:{"display":"block !important"},attrs:{"role":"document"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-dialog-scrollable",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content",staticStyle:{"background":"none","border":"none"}},[_c('div',{staticClass:"modal-body"},_vm._l((_vm.bulkForm),function(pasien,index){return _c('div',{key:index,staticClass:"multiple-content",on:{"click":function($event){$event.preventDefault();return _vm.changePage(index + 1)}}},[_c('h5',{staticStyle:{"margin-right":"15px"}},[_vm._v(" "+_vm._s(index + 1)+" / "+_vm._s(_vm.formCount)+" ")]),_c('div',{staticStyle:{"margin-bottom":"35px"}},[_c('h5',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(pasien[`dataPasien`].namaDepan ? pasien[`dataPasien`].namaDepan : "Nama Depan Kosong")+" ")]),_vm._v(" Test Terpilih: "),(
                                        pasien[`dataKlinis`].tipe_test
                                            .length > 0
                                    )?_c('div',{staticStyle:{"margin-bottom":"40px"}},[_c('div',{staticClass:"d-flex"},[_vm._l((pasien[
                                                `dataKlinis`
                                            ].tipe_test),function(test,index){return _c('div',{key:index},[(index < 2)?_c('label',{class:test.nama == 'PCR'
                                                        ? 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active'
                                                        : 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active'},[_vm._v(" "+_vm._s(test.nama)+" ")]):(
                                                    pasien['dataKlinis']
                                                        .length == 0
                                                )?_c('label',{class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning',staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","width":"100px"}},[_vm._v(" "+_vm._s("KOSONG")+" ")]):_vm._e()])}),(
                                                pasien[`dataKlinis`]
                                                    .tipe_test > 2
                                            )?_c('label',{class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning',staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","width":"100px"}},[_vm._v(" + "+_vm._s(index - 2)+"... ")]):_vm._e()],2)]):_c('p',[_vm._v("Tidak ada")]),_c('p',[_vm._v(" "+_vm._s(pasien[`dataPasien`].nik ? pasien[`dataPasien`].nik : "NIK Kosong")+" ")]),_c('p',[_vm._v(" "+_vm._s(!pasien[`dataPasien`].telp ? "No.Telp Kosong" : pasien[`dataPasien`].telp)+" ")]),_c('p',[_vm._v(" "+_vm._s(!pasien[`dataPasien`].email ? "Email Kosong" : pasien[`dataPasien`].email)+" ")]),_c('p',[_vm._v(" "+_vm._s(!pasien[`dataPasien`].tglLahir ? "Tgl Lahir Kosong" : pasien[`dataPasien`].tglLahir)+" ")])]),_c('button',{staticClass:"close multiple-close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){$event.preventDefault();return _vm.deleteConfirmation(index)}}},[_c('i',{staticClass:"far fa-trash-alt",staticStyle:{"font-size":"30px"}})])])}),0)])])]),_c('button',{staticClass:"float-plus",on:{"click":function($event){$event.preventDefault();return _vm.addForm.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-plus my-float-plus"})])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(" Pilih Tanggal Kunjungan:"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v("Kewarganegaraan:"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-lg-4 col-form-label",attrs:{"for":"negara"}},[_vm._v("Negara :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-lg-4 col-form-label"},[_vm._v(" NIK / E-Kitas / Passport :"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('span',{staticClass:"col-lg-4"},[_vm._v("Tanda ("),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(") wajib untuk diisi.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"multiple-form-close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('i',{staticClass:"far fa-times-circle"})])
}]

export { render, staticRenderFns }