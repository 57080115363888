<template>
    <div style="position: relative;">
        <div class="sticky-buttons">
            <button
                @click="backToMenu"
                class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
            >
                <i style="color: white;" class="fas fa-arrow-left"></i>
            </button>
            <button
                class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                @click="multipleForm"
            >
                {{ `${formPage} / ${formCount}` }}
            </button>
        </div>

        <div v-if="formCount > 0">
            <div
                class="d-flex align-items-center"
                style="flex-direction: column; margin-top: 3rem;"
            >
                <form class="create-form" autocomplete="off">
                    <div
                        role="tablist"
                        class="accordion"
                        style="padding: 1.25rem;"
                    >
                        <label
                            id="ktp-label"
                            class="col-lg-4 btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                            for="ktp-ocr"
                        >
                            ktp ocr
                            <input
                                type="file"
                                name=""
                                id="ktp-ocr"
                                capture="camera"
                                @change="setImage"
                                autocomplete="off"
                                accept="image/png, image/jpeg"
                            />
                        </label>
                        <cropper-modal
                            :imgSrc="imgSrc"
                            :fileType="fileType"
                            :fileName="fileName"
                            :formPage="formPage"
                            :changedValue.sync="emittedData"
                            v-if="fileType && imgSrc && isCropperModal"
                        >
                        </cropper-modal>
                        <!-- v-if="
                                bulkForm[formPage - 1][`dataPasien`].linkKtp &&
                                    ocrLoading
                            " -->

                        <div
                            v-if="bulkForm[formPage - 1][`dataPasien`].linkKtp"
                            class="form-group row"
                        >
                            <label
                                :class="
                                    ocrLoading
                                        ? 'col-lg-4 col-form-label shimmer'
                                        : 'col-lg-4 col-form-label'
                                "
                            >
                                {{
                                    ocrLoading
                                        ? "SCANNING FOTO KTP"
                                        : "Foto Ktp Tersimpan"
                                }}
                                <span v-if="!ocrLoading"
                                    ><i
                                        style="color: green;"
                                        class="fas fa-check"
                                    ></i
                                ></span>
                            </label>

                            <img
                                class="col-lg-6 ktp-image-preview"
                                :style="
                                    ocrLoading ? 'filter: grayscale(100%);' : ''
                                "
                                v-if="
                                    bulkForm[formPage - 1][`dataPasien`].linkKtp
                                "
                                :src="
                                    `data:image/jpeg;base64,${
                                        bulkForm[formPage - 1][`dataPasien`]
                                            .linkKtp
                                    }`
                                "
                                alt="Cropped Image"
                            />
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">
                                Pilih Tanggal Kunjungan:<span style="color:red;"
                                    >*</span
                                ></label
                            >
                            <div class="col-lg-6">
                                <vue-date-picker
                                    @change="
                                        tglKunjunganMe(
                                            tglKunjungan,
                                            'kunjungan'
                                        )
                                    "
                                    :format="dateFormat"
                                    v-model="tglKunjungan"
                                    :scope="'form-2'"
                                    :validation="'required'"
                                    :validationName="'Tanggal Kunjungan'"
                                    :name="'tanggalKunjungan'"
                                    :clearable="false"
                                    :editable="false"
                                    :disabled-date="disabledDateFunc"
                                    :eventCalendarChange="eventCalendarChange"
                                ></vue-date-picker>
                                <small
                                    v-if="isYesterdayDate"
                                    id="yesterDate"
                                    class="form-text text-danger"
                                >
                                    Tanggal minimal hari ini</small
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                                >Kewarganegaraan:<span style="color:red;"
                                    >*</span
                                ></label
                            >
                            <div style="margin-left: 20px;" class="col-lg-6">
                                <label class="kt-radio">
                                    <input
                                        autocomplete="off"
                                        @click="clearSelectedNation"
                                        type="radio"
                                        class="form-control"
                                        name="kewarganegaraan"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .kewarganegaraan
                                        "
                                        value="Indonesia"
                                    />
                                    WNI <span></span>
                                </label>
                                <label class="kt-radio">
                                    <input
                                        autocomplete="off"
                                        type="radio"
                                        class="form-control"
                                        name="kewarganegaraan"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .kewarganegaraan
                                        "
                                        value="WNA"
                                    />
                                    WNA <span></span>
                                </label>
                            </div>
                        </div>
                        <div
                            class="form-group row"
                            v-if="
                                bulkForm[formPage - 1][`dataPasien`]
                                    .kewarganegaraan == 'WNA'
                            "
                        >
                            <label class="col-lg-4 col-form-label" for="negara"
                                >Negara :<span style="color:red;"
                                    >*</span
                                ></label
                            >
                            <div class="col-lg-6">
                                <multiselect
                                    :allow-empty="false"
                                    id="negara"
                                    :searchable="true"
                                    :close-on-select="true"
                                    openDirection="bottom"
                                    v-model="
                                        bulkForm[formPage - 1][`dataPasien`]
                                            .negara
                                    "
                                    :options="nations"
                                    label="name"
                                >
                                </multiselect>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">
                                NIK / E-Kitas / Passport :<span
                                    style="color:red;"
                                    >*</span
                                ></label
                            >
                            <div class="col-lg-6">
                                <input
                                    autocomplete="off"
                                    title="NIK"
                                    pattern="^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$"
                                    :maxlength="
                                        bulkForm[formPage - 1][`dataPasien`]
                                            .kewarganegaraan == 'Indonesia' ||
                                        bulkForm[formPage - 1][`dataPasien`]
                                            .kewarganegaraan == null
                                            ? '16'
                                            : ''
                                    "
                                    :class="{
                                        'is-invalid': errors.has('ssn')
                                    }"
                                    :type="
                                        bulkForm[formPage - 1][`dataPasien`]
                                            .kewarganegaraan == 'Indonesia' ||
                                        bulkForm[formPage - 1][`dataPasien`]
                                            .kewarganegaraan == null
                                            ? 'number'
                                            : 'text'
                                    "
                                    class="form-control"
                                    placeholder="NIK KTP Pasien"
                                    data-vv-as="ssn"
                                    data-vv-scope="form"
                                    id="nik"
                                    name="ssn"
                                    v-model="
                                        bulkForm[formPage - 1][`dataPasien`].nik
                                    "
                                    @keyup="inputSsn"
                                    @paste="inputSsn"
                                />
                                <div
                                    v-if="!isNikValid"
                                    style="display: inline;"
                                    class="invalid-feedback"
                                >
                                    NIK tidak valid
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                                >{{ $t("common.phoneNo") }} :<span
                                    style="color:red;"
                                    >*</span
                                ></label
                            >
                            <div class="col-lg-6">
                                <input
                                    name="telepon"
                                    id="telepon"
                                    autocomplete="telepon"
                                    class="form-control"
                                    @input="telepon"
                                    type="number"
                                    placeholder="No. Telepon Pasien"
                                    v-model="
                                        bulkForm[formPage - 1].dataPasien.telp
                                    "
                                />
                                <div
                                    v-show="!isNoTelpValid"
                                    style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #fd397a;"
                                >
                                    No Telepon Tidak Valid
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                                >{{ $t("common.email") }} :<span
                                    style="color:red;"
                                    >*</span
                                ></label
                            >
                            <div class="col-lg-6">
                                <input
                                    autocomplete="off"
                                    type="email"
                                    class="form-control"
                                    name="email"
                                    placeholder="Email Pasien"
                                    @input="emailInput"
                                    v-model="
                                        bulkForm[formPage - 1][`dataPasien`]
                                            .email
                                    "
                                />
                                <div
                                    v-show="!isEmailValid"
                                    style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #fd397a;"
                                >
                                    Email Tidak Valid
                                </div>
                            </div>
                        </div>
                        <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                        >
                            <b-button
                                :pressed="buttonToggle['data-pasien']"
                                block
                                v-b-toggle.data-pasien
                                class="m-1"
                                @click="toggleTab('data-pasien')"
                            >
                                Data Pasien</b-button
                            >
                        </b-card-header>
                        <b-collapse role="tabpanel" visible id="data-pasien">
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >{{ $t("common.firstName") }}:<span
                                        style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <input
                                        autocomplete="patientFirstName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Nama Depan Pasien"
                                        name="patientFirstName"
                                        id="patientFirstName"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .namaDepan
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >{{ $t("common.lastName") }}:
                                    <!-- <span
                                        style="color:red;"
                                        >*</span
                                    > -->
                                </label>
                                <div class="col-lg-6">
                                    <input
                                        autocomplete="patientLastName"
                                        type="text"
                                        class="form-control"
                                        placeholder="Nama Belakang Pasien"
                                        name="patientLastName"
                                        id="patientLastName"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .namaBelakang
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >{{ $t("common.tanggalLahir") }}:
                                    <span style="color:red;">*</span>
                                </label>
                                <div class="col-lg-6">
                                    <vue-date-picker
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .tglLahir
                                        "
                                        :scope="'form-2'"
                                        :validation="'required'"
                                        :validationName="'Tanggal Lahir'"
                                        :name="'tanggalLahir'"
                                        :clearable="false"
                                        :editable="false"
                                        :disabled-date="'notAfterToday'"
                                        :format="dateFormat"
                                    >
                                    </vue-date-picker>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >{{ $t("common.gender") }}:<span
                                        style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <label class="kt-radio">
                                        <input
                                            autocomplete="off"
                                            type="radio"
                                            class="form-control"
                                            name="jenis_kelamin"
                                            v-model="
                                                bulkForm[formPage - 1][
                                                    `dataPasien`
                                                ].jenisKelamin
                                            "
                                            value="M"
                                        />
                                        Laki-Laki <span></span>
                                    </label>
                                    <label class="kt-radio">
                                        <input
                                            autocomplete="off"
                                            type="radio"
                                            class="form-control"
                                            name="jenis_kelamin"
                                            v-model="
                                                bulkForm[formPage - 1][
                                                    `dataPasien`
                                                ].jenisKelamin
                                            "
                                            value="F"
                                        />
                                        Perempuan <span></span>
                                    </label>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >Alamat :<span style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <textarea
                                        rows="4"
                                        placeholder="Alamat Pasien"
                                        type="text"
                                        class="form-control"
                                        name="alamat"
                                        id="alamat"
                                        autocomplete="alamat"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .alamat
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >RT/RW :</label
                                >
                                <div id="rt-rw-form" class="col-lg-6">
                                    <input
                                        id="0"
                                        class="form-control rt-rw"
                                        maxlength="3"
                                        type="number"
                                        ref="input-0"
                                        data-index="0"
                                        style="width: 70px;display: inline;"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .rt
                                        "
                                        @change="rtChange"
                                    />
                                    /
                                    <input
                                        id="1"
                                        data-index="1"
                                        ref="input-1"
                                        class="form-control rt-rw"
                                        maxlength="3"
                                        type="number"
                                        style="width: 70px;display: inline;"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .rw
                                        "
                                        @change="rwChange"
                                    />
                                    <!-- <input
                                    autocomplete="off"
                                    type="number"
                                    class="form-control"
                                    name="rt_rw"
                                    v-model="
                                        bulkForm[formPage - 1][`dataPasien`]
                                            .rtRw
                                    "
                                /> -->
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >kelurahan :<span style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <input
                                        autocomplete="kelurahan"
                                        type="text"
                                        class="form-control"
                                        name="kelurahan"
                                        id="kelurahan"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .kelurahan
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >Kecamatan :<span style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <input
                                        autocomplete="kecamatan"
                                        type="text"
                                        class="form-control"
                                        name="kecamatan"
                                        id="kecamatan"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .kecamatan
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >Kabupaten :<span style="color:red;"
                                        >*</span
                                    ></label
                                >

                                <div class="col-lg-6">
                                    <input
                                        autocomplete="kabupaten"
                                        type="text"
                                        class="form-control"
                                        name="kabupaten"
                                        id="kabupaten"
                                        v-model="
                                            bulkForm[formPage - 1][`dataPasien`]
                                                .kabupaten
                                        "
                                    />
                                </div>
                            </div>
                        </b-collapse>
                        <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                        >
                            <b-button
                                :pressed="buttonToggle['data-klinis']"
                                @click="toggleTab('data-klinis')"
                                block
                                v-b-toggle.data-klinis
                                class="m-1"
                                >Data Klinis</b-button
                            >
                        </b-card-header>
                        <b-collapse
                            style="margin-bottom: 50px;"
                            role="tabpanel"
                            visible
                            id="data-klinis"
                        >
                            <div class="form-group row">
                                <label
                                    for="tipe-test"
                                    class="col-lg-4 col-form-label"
                                >
                                    Tipe Test :<span style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <multiselect
                                        track-by="nama"
                                        label="nama"
                                        :allow-empty="false"
                                        :multiple="true"
                                        openDirection="bottom"
                                        :searchable="false"
                                        :close-on-select="true"
                                        v-model="
                                            bulkForm[formPage - 1].dataKlinis
                                                .tipe_test
                                        "
                                        :options="optionsPaket"
                                        :custom-label="paketPrice"
                                        placeholder="Pilih Tipe Test"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >Tujuan Pemeriksaan :<span
                                        style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <multiselect
                                        openDirection="bottom"
                                        placeholder="Pilih Tujuan Pemeriksaan"
                                        :allow-empty="false"
                                        :searchable="false"
                                        :close-on-select="true"
                                        v-model="
                                            bulkForm[formPage - 1][`dataKlinis`]
                                                .tujuanPemeriksaan
                                        "
                                        :options="tujuanPemeriksaan"
                                        :custom-label="customLabel"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >Riwayat Kontak :<span style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <multiselect
                                        openDirection="bottom"
                                        placeholder="Pilih Riwayat Kontak"
                                        :searchable="false"
                                        :close-on-select="true"
                                        v-model="
                                            bulkForm[formPage - 1][`dataKlinis`]
                                                .riwayatKontak
                                        "
                                        :options="riwayatKontakOptions"
                                        :custom-label="customLabel"
                                    ></multiselect>
                                </div>
                            </div>
                            <div
                                v-if="
                                    bulkForm[formPage - 1][`dataKlinis`]
                                        .riwayatKontak == 'ADA'
                                "
                                class="form-group row"
                            >
                                <label class="col-lg-4 col-form-label"
                                    >Riwayat Kontak Detail :<span
                                        style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <textarea
                                        rows="4"
                                        placeholder="Kontak Detail Pasien"
                                        type="text"
                                        class="form-control"
                                        name="kontak_detail"
                                        v-model="
                                            bulkForm[formPage - 1][`dataKlinis`]
                                                .riwayatKontakDetail
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >Gejala :<span style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <label class="kt-radio">
                                        <input
                                            autocomplete="off"
                                            @click="clearDetailGejala"
                                            type="radio"
                                            class="form-control"
                                            name="gejala"
                                            v-model="
                                                bulkForm[formPage - 1][
                                                    `dataKlinis`
                                                ].gejala
                                            "
                                            :value="false"
                                        />
                                        Tidak Bergejala <span></span>
                                    </label>
                                    <label class="kt-radio">
                                        <input
                                            autocomplete="off"
                                            type="radio"
                                            class="form-control"
                                            name="gejala"
                                            v-model="
                                                bulkForm[formPage - 1][
                                                    `dataKlinis`
                                                ].gejala
                                            "
                                            :value="true"
                                        />
                                        Bergejala <span></span>
                                    </label>
                                </div>
                            </div>
                            <div
                                v-if="
                                    bulkForm[formPage - 1][`dataKlinis`].gejala
                                "
                                class="form-group row"
                            >
                                <label class="col-lg-4 col-form-label"
                                    >Detail Gejala :<span style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <textarea
                                        rows="4"
                                        placeholder="Detail Gejala Pasien"
                                        type="text"
                                        class="form-control"
                                        name="gejala_detail"
                                        v-model="
                                            bulkForm[formPage - 1][`dataKlinis`]
                                                .gejalaDetail
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    bulkForm[formPage - 1][`dataKlinis`].gejala
                                "
                                class="form-group row"
                            >
                                <label class="col-lg-4 col-form-label">
                                    Tanggal Awal Gejala:<span style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <vue-date-picker
                                        v-model="
                                            bulkForm[formPage - 1][`dataKlinis`]
                                                .tglAwalGejala
                                        "
                                        :format="dateFormat"
                                        :scope="'form-2'"
                                        :validation="'required'"
                                        :validationName="'Tanggal Kunjungan'"
                                        :name="'tanggalKunjungan'"
                                        :clearable="false"
                                        :editable="false"
                                        :disabled-date="'notAfterToday'"
                                    ></vue-date-picker>
                                    <small
                                        v-if="isTomorrowDate"
                                        id="passwordHelpBlock"
                                        class="form-text text-danger"
                                    >
                                        Tanggal maksimal hari ini</small
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >Memiliki Penyakit Penyerta :<span
                                        style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <label class="kt-radio">
                                        <input
                                            autocomplete="off"
                                            @click="clearPenyakitDetail"
                                            type="radio"
                                            class="form-control"
                                            name="penyakit-penyerta"
                                            v-model="
                                                bulkForm[formPage - 1][
                                                    `dataKlinis`
                                                ].penyakitPenyerta
                                            "
                                            :value="false"
                                        />
                                        Tidak Ada <span></span>
                                    </label>
                                    <label class="kt-radio">
                                        <input
                                            autocomplete="off"
                                            type="radio"
                                            class="form-control"
                                            name="penyakit-penyerta"
                                            v-model="
                                                bulkForm[formPage - 1][
                                                    `dataKlinis`
                                                ].penyakitPenyerta
                                            "
                                            :value="true"
                                        />
                                        Ada <span></span>
                                    </label>
                                </div>
                            </div>
                            <div
                                v-if="
                                    bulkForm[formPage - 1][`dataKlinis`]
                                        .penyakitPenyerta
                                "
                                class="form-group row"
                            >
                                <label class="col-lg-4 col-form-label"
                                    >Detail Penyakit Penyerta :<span
                                        style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <textarea
                                        rows="4"
                                        placeholder="Detail Penyakit Peserta Pasien"
                                        type="text"
                                        class="form-control"
                                        name="penyakit_penyerta_detail"
                                        v-model="
                                            bulkForm[formPage - 1][`dataKlinis`]
                                                .penyakitPenyertaDetail
                                        "
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label"
                                    >Riwayat Perjalanan :<span
                                        style="color:red;"
                                        >*</span
                                    ></label
                                >
                                <div class="col-lg-6">
                                    <multiselect
                                        openDirection="bottom"
                                        :allow-empty="false"
                                        placeholder="Riwayat Perjalanan"
                                        :searchable="false"
                                        :close-on-select="true"
                                        v-model="
                                            bulkForm[formPage - 1][`dataKlinis`]
                                                .riwayatPerjalanan
                                        "
                                        :options="riwayatPerjalananOptions"
                                        :custom-label="customLabel"
                                    ></multiselect>
                                </div>
                            </div>
                        </b-collapse>
                        <div class="form-group row">
                            <span class="col-lg-4"
                                >Tanda (<span style="color:red;">*</span>) wajib
                                untuk diisi.</span
                            >
                        </div>
                    </div>
                    <button
                        style="width:100%"
                        @click.prevent="saveConfirmation"
                        class="button-bar btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                    >
                        Simpan
                    </button>
                </form>
            </div>
        </div>
        <div v-else><h1>no form</h1></div>
        <required-modal
            :requiredInput="validationInput"
            v-if="isShowRequiredModal"
        ></required-modal>

        <div
            v-if="isModalVisible"
            class="modal fade"
            :id="modalName"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalScrollableTitle"
            aria-hidden="true"
        >
            <button
                type="button"
                class="multiple-form-close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <i class="far fa-times-circle"></i>
            </button>
            <div
                class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                role="document"
                style=" display: block !important;"
            >
                <div
                    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                    role="document"
                >
                    <div
                        style="background: none;border: none;"
                        class="modal-content"
                    >
                        <div class="modal-body">
                            <div
                                v-for="(pasien, index) in bulkForm"
                                :key="index"
                                class="multiple-content"
                                @click.prevent="changePage(index + 1)"
                            >
                                <h5 style="margin-right: 15px;">
                                    {{ index + 1 }} / {{ formCount }}
                                </h5>
                                <div style="margin-bottom: 35px;">
                                    <h5 style="color: white; !important">
                                        {{
                                            pasien[`dataPasien`].namaDepan
                                                ? pasien[`dataPasien`].namaDepan
                                                : "Nama Depan Kosong"
                                        }}
                                    </h5>
                                    Test Terpilih:
                                    <div
                                        style="margin-bottom: 40px;"
                                        v-if="
                                            pasien[`dataKlinis`].tipe_test
                                                .length > 0
                                        "
                                    >
                                        <div class="d-flex">
                                            <div
                                                v-for="(test, index) in pasien[
                                                    `dataKlinis`
                                                ].tipe_test"
                                                :key="index"
                                            >
                                                <label
                                                    v-if="index < 2"
                                                    :class="
                                                        test.nama == 'PCR'
                                                            ? 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active'
                                                            : 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active'
                                                    "
                                                >
                                                    {{ test.nama }}
                                                </label>
                                                <label
                                                    v-else-if="
                                                        pasien['dataKlinis']
                                                            .length == 0
                                                    "
                                                    :class="
                                                        'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning'
                                                    "
                                                    style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100px;"
                                                >
                                                    {{ "KOSONG" }}
                                                </label>
                                            </div>
                                            <label
                                                v-if="
                                                    pasien[`dataKlinis`]
                                                        .tipe_test > 2
                                                "
                                                :class="
                                                    'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning'
                                                "
                                                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 100px;"
                                            >
                                                + {{ index - 2 }}...
                                            </label>
                                        </div>
                                    </div>
                                    <p v-else>Tidak ada</p>
                                    <p>
                                        {{
                                            pasien[`dataPasien`].nik
                                                ? pasien[`dataPasien`].nik
                                                : "NIK Kosong"
                                        }}
                                    </p>
                                    <p>
                                        {{
                                            !pasien[`dataPasien`].telp
                                                ? "No.Telp Kosong"
                                                : pasien[`dataPasien`].telp
                                        }}
                                    </p>
                                    <p>
                                        {{
                                            !pasien[`dataPasien`].email
                                                ? "Email Kosong"
                                                : pasien[`dataPasien`].email
                                        }}
                                    </p>
                                    <p>
                                        {{
                                            !pasien[`dataPasien`].tglLahir
                                                ? "Tgl Lahir Kosong"
                                                : pasien[`dataPasien`].tglLahir
                                        }}
                                    </p>
                                </div>
                                <button
                                    @click.prevent="deleteConfirmation(index)"
                                    type="button"
                                    class="close multiple-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i
                                        style="font-size: 30px;"
                                        class="far fa-trash-alt"
                                    ></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button @click.prevent="addForm" class="float-plus">
                <i class="fa fa-plus my-float-plus"></i>
            </button>
        </div>
    </div>
</template>

<script>
import Axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
import formPasienModel from "../../../model/form-pasien-model";
import formDataKlinisPasienModel from "../../../model/form-data-klinis-pasien-model";
import DatePicker from "../../_general/DatePicker.vue";
import VueDatePicker from "../../_general/VueDatePicker.vue";
import GenderOption from "../../_general/GenderOption.vue";
import PhoneNumberPatient from "../../_general/PhoneNumber.vue";
import TestCards from "../components/TestCards.vue";
import Multiselect from "vue-multiselect";
import VueCropper from "vue-cropperjs";
import SweetAlertTemplate from "../../_general/SweetAlert.vue";
import RequiredModal from "./RequiredModal.vue";
import CropperModal from "../components/CropperModal.vue";

import {RepositoryFactory} from "../../../repositories/RepositoryFactory"
const HolidayRepository = RepositoryFactory.get("holiday")

const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    name: "create-booking",
    components: {
        GenderOption,
        DatePicker,
        PhoneNumberPatient,
        Multiselect,
        VueCropper,
        TestCards,
        VueDatePicker,
        RequiredModal,
        CropperModal
    },
    data() {
        return {
            dateFormat: "DD-MMM-YYYY",
            tglKunjungan: moment().format("YYYY-MM-DD"),
            formPage: 1,
            bulkForm: [],
            fileType: null,
            fileName: null,
            picture: null,
            modalName: "multiple-form-modal",
            imgSrc: null,
            cropImg: "", // dataUrl
            preViewImg: "",
            data: null,
            emittedData: null,
            testOptions: [
                { nama: "PCR", price: 150000 },
                { nama: "Rapid Test", price: 50000 }
            ],
            riwayatKontakOptions: ["ADA", "TIDAK_ADA", "TIDAK_TAHU"],
            riwayatPerjalananOptions: [
                "LUAR_NEGERI",
                "DALAM_NEGERI",
                "TIDAK_ADA"
            ],
            negaraOptions: [],
            tujuanPemeriksaan: [
                "PERJALANAN_DALAM_NEGERI",
                "PERJALANAN_LUAR_NEGERI",
                "SCREENING"
            ],
            validationInput: [],
            buttonToggle: { "data-pasien": false, "data-klinis": false },
            ocrResult: {},
            isEmailValid: true,
            ocrLoading: false,
            isModalVisible: false,
            croppedImgViewer: false,
            isYesterdayDate: false,
            isNikValid: true,
            isTomorrowDate: false,
            isNoTelpValid: true
        };
    },
    computed: {
        ...mapState("DriveThruStore", {
            isForm: state => state.isForm,
            formCount: state => state.formCount,
            optionsPaket: state => state.optionsPaket,
            nations: state => state.nations,
            isShowRequiredModal: state => state.isShowRequiredModal,
            isCropperModal: state => state.isCropperModal,
            defaultRangeDateBooking: state => state.defaultRangeDateBooking,
            holidayDates: state => state.holidayDates,
            offDays: state => state.offDays
        })
    },
    watch: {
        emittedData(val) {
            if (val) {
                this.setIsCropperModal(false);
                this.cropImg = val.dataUri + val.linkKtp;
                this.fileName = val.imgKtp;
                this.bulkForm[val.formIndex].dataPasien.imgKtp = val.imgKtp;
                this.fileType = val.fileType;
                this.sendImage(val.formIndex);
                $("#cropperModal").modal("hide");
            }
        }
    },
    methods: {
        ...mapMutations("DriveThruStore", {
            setForm: "SET_FORM",
            setFormCount: "SET_FORM_COUNT",
            calFormCount: "CAL_FORM_COUNT",
            setIsFormSave: "SET_FORM_SAVE",
            setPatientForms: "SET_PATIENT_FORMS",
            setIsShowRequiredModal: "SET_IS_SHOW_REQUIRED_MODAL",
            setTglKunjungan: "SET_TGL_KUNJUNGAN",
            setIsCropperModal: "SET_IS_CROPPER_MODAL",
            setDefaultHolidayDates  : "SET_DEFAULT_HOLIDAY_DATES"
        }),
        ...mapActions("DriveThruStore", {
            getPaket: "getPaket",
            getNations: "getNations",
            postPatientOcr: "postPatientOcr",
            getDefaultRangeDate: "getDefaultRangeDate"
        }),
        ocrBeginLoading() {
            this.ocrLoading = true;
        },
        toggleTab(tab) {
            // if (tab == 'data-pasien') {
            this.buttonToggle[tab] = !this.buttonToggle[tab];
            // }
        },
        enumerateDaysBetweenDates(startDate, endDate) {
            let dates = [];

            let currDate = moment(startDate).startOf("day");
            let lastDate = moment(endDate).startOf("day");
            if (startDate !== endDate) {
                // console.log("sama");
                dates.push(moment(startDate).format("DD-MM-YYYY"));
            }
            while (currDate.add(1, "days").diff(lastDate) < 0) {
                dates.push(currDate.clone().format("DD-MM-YYYY"));
            }
            dates.push(moment(endDate).format("DD-MM-YYYY"));
            return dates;
        },
        async eventCalendarChange(date) {
            let startDate = moment()
                .startOf("month")
                .format("YYYY-MM-DD");
            let endDate = moment()
                .endOf("month")
                .format("YYYY-MM-DD");
            if (date) {
                startDate = moment(date)
                    .startOf("month")
                    .format("YYYY-MM-DD");
                endDate = moment(date)
                    .endOf("month")
                    .format("YYYY-MM-DD");
            }
            try {
                        const datesPromisses = await Promise.all([
                            HolidayRepository.getHolidays({
                                params:{
                                holidayStart: startDate,
                                holidayEnd: endDate,
                                page_size: 1000,
                                holidayType: "INCLUDE"
                                }
                            }),
                            HolidayRepository.getHolidays({
                            params: {
                            holidayStart: startDate,
                            holidayEnd: endDate,
                            selectedPlatform: "PCR_DT",
                            holidayType: "EXCLUDE",
                            page_size: 1000
                            }
                            }),
                            HolidayRepository.getHolidays({
                            params: {
                            holidayStart: startDate,
                            holidayEnd: endDate,
                            holidayType: "EXCLUDE",
                            page_size: 1000
                            }
                            })
                        ])
                        let excludeAll =  datesPromisses[2].data.filter(date => {
                            if (date.detailHoliday) {
                                let isReturn = false
                                date.detailHoliday.forEach(item => {
                                    if(!item.platform || item.platform != "PCR_DT"){
                                        isReturn = true
                                    }
                                })
                                return isReturn
                            }
                        })
                       excludeAll = excludeAll.map(mapped => {
                            return {
                                startDate: mapped.tanggalMulai,
                                endDate:mapped.tanggalSelesai
                            }
                        })
                        const pcrLibur = datesPromisses[0].data.map(date => {
                            return {
                                startDate: date.tanggalMulai,
                                endDate:date.tanggalSelesai
                            }
                        })
                        let liburDates = []
                        excludeAll.forEach(item => {
                           let itemDates = this.enumerateDaysBetweenDates(item.startDate , item.endDate)
                           itemDates.forEach(date => {
                                liburDates.push(date)
                           })
                        })
                        pcrLibur.forEach(item => {
                           let itemDates = this.enumerateDaysBetweenDates(item.startDate , item.endDate)
                           itemDates.forEach(date => {
                                liburDates.push(date)
                           })
                        })
                        let uniqLiburDates = _.uniqBy(liburDates)
                        
                        const pcrTidakLibur = datesPromisses[1].data.map(date => {
                            return {
                                startDate: date.tanggalMulai,
                                endDate:date.tanggalSelesai
                            }
                        })
                        let tidakLiburDates = []
                        pcrTidakLibur.forEach(item => {
                           let itemDates = this.enumerateDaysBetweenDates(item.startDate , item.endDate)
                           itemDates.forEach(date => {
                                tidakLiburDates.push(date)
                           })
                        })
                        let uniqTidakLiburDates =  _.uniqBy(tidakLiburDates)
                        let liburFix = uniqLiburDates.filter(date => {
                            if(!uniqTidakLiburDates.includes(date)){
                                return date
                            }
                        })
                        this.setDefaultHolidayDates( liburFix )
              
            } catch (error) {
                console.log(error , "Dokter Service Holiday Error");
            }
        },
        disabledDateFunc(date) {
            let rangeDate = this.defaultRangeDateBooking
                ? this.defaultRangeDateBooking
                : 14;
            let holidayDates = this.holidayDates ? this.holidayDates : [];
            let offDays = this.offDays ? this.offDays : [];
            const compareDate = moment(date);
            const yesterday = moment().subtract(1, "days");
            const endDate = moment().add(rangeDate, "days");
            let isHoliday = false;
            let isOffDay = false;

            if (holidayDates && Array.isArray(holidayDates)) {
                if (holidayDates.length > 0) {
                    //apabila ada hari libur
                    holidayDates.forEach(el => {
                        if (moment(el, "DD-MM-YYYY").isSame(date, "day")) {
                            //apabila hari libur sama dengan tanggal di datepicker
                            isHoliday = true;
                        }
                    });
                }
                // else {
                //     if (moment(compareDate).weekday() == 6) {
                //         isHoliday = true;
                //     }
                // }
            }
            if (isHoliday) {
                return isHoliday; // disable hari libur
            }
            if (offDays && Array.isArray(offDays)) {
                if (offDays.length > 0) {
                    offDays.forEach(ele => {
                        if (moment(date).weekday() == ele) {
                            isOffDay = true;
                        }
                    });
                }
            }
            if (isOffDay) {
                // apabila hari off maka disable
                return true;
            } else {
                return !compareDate.isBetween(yesterday, endDate);
            }
        },
        telepon(e) {
            const pattern = /^(\+62|62)?[\s-]?0?8[1-9]{1}\d{1}[\s-]?\d{4}[\s-]?\d{2,5}$/;
            this.isNoTelpValid = pattern.test(
                this.bulkForm[this.formPage - 1].dataPasien.telp
            );
            if (this.isNoTelpValid) {
                this.bulkForm[
                    this.formPage - 1
                ].dataPasien.telp = this.bulkForm[
                    this.formPage - 1
                ].dataPasien.telp.replace(/^(\+62|62|0)/, "62");
                this.bulkForm[
                    this.formPage - 1
                ].dataPasien.telp = this.bulkForm[
                    this.formPage - 1
                ].dataPasien.telp.replace(/\D+/g, "");
            }
        },
        emailInput() {
            const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            this.isEmailValid = pattern.test(
                this.bulkForm[this.formPage - 1].dataPasien.email
            );
        },
        rtChange(e) {
            if (e.srcElement.id == 0) {
                this.bulkForm[this.formPage - 1].dataPasien.rtRw = "";
                this.bulkForm[
                    this.formPage - 1
                ].dataPasien.rtRw += this.bulkForm[
                    this.formPage - 1
                ].dataPasien.rt;
                if (this.bulkForm[this.formPage - 1].dataPasien.rw) {
                    this.bulkForm[
                        this.formPage - 1
                    ].dataPasien.rtRw += this.bulkForm[
                        this.formPage - 1
                    ].dataPasien.rw;
                }
            }
        },
        rwChange(e) {
            if (e.srcElement.id == 1) {
                this.bulkForm[this.formPage - 1].dataPasien.rtRw =
                    this.bulkForm[this.formPage - 1].dataPasien.rt +
                    this.bulkForm[this.formPage - 1].dataPasien.rw;
            }
        },
        ocrDoneLoading() {
            this.ocrLoading = false;
        },
        tglKunjunganMe(val, command) {
            let today = moment().format("YYYY-MM-DD");
            let tgl = moment(val).format("YYYY-MM-DD");
            if (command == "gejala") {
                if (tgl > today) {
                    this.isTomorrowDate = true;
                } else {
                    this.isTomorrowDate = false;
                }
            } else {
                if (tgl < today) {
                    this.isYesterdayDate = true;
                } else {
                    this.isYesterdayDate = false;
                }
            }
            if (command == "kunjungan") {
                this.setTglKunjungan(tgl);
            }
        },
        customLabel(value) {
            return value.replaceAll("_", " ");
        },
        paketPrice({ nama, price }) {
            const localePrice = price.toLocaleString("id", {
                style: "currency",
                currency: "IDR"
            });
            return `${nama} - ${localePrice}`;
        },
        clearSelectedNation() {
            this.bulkForm[this.formPage - 1][`dataPasien`].negara = null;
        },
        clearPenyakitDetail() {
            this.bulkForm[this.formPage - 1][
                `dataKlinis`
            ].penyakitPenyertaDetail = null;
        },
        clearDetailGejala() {
            this.bulkForm[this.formPage - 1][`dataKlinis`].gejalaDetail = null;
            this.bulkForm[this.formPage - 1][`dataKlinis`].tglAwalGejala = null;
        },
        applyOcrResult(index, data) {
            if (this.formPage == +index + 1) {
                this.setIsCropperModal(false);
                this.cropImg = null;
                this.imgSrc = null;

                $("#" + "cropperModal").modal("hide");

                this.bulkForm[index].dataPasien.linkFoto = data.data.faceImage;
                this.bulkForm[index].dataPasien.imgFoto =
                    data.data.filefaceName;
                let dataHtml = `<div class="form-group">
                 <p> Provinsi : ${data.data.provinsi} </p>
                 <p> Kota : ${data.data.kota} </p>
            ${data.data.nik ? `<p> NIK : ${data.data.nik} </p> ` : ``}

             <p> Nama Depan : ${data.data.nama_depan} </p>
             <p> Nama Belakang : ${data.data.nama_belakang} </p>
             ${
                 data.data.tgl_lahir
                     ? `<p> Tanggal Lahir : ${moment(
                           data.data.tgl_lahir,
                           "DD-MM-YYYY"
                       ).format("YYYY-MM-DD")} </p>`
                     : ``
             }

             <p> Jenis Kelamin : ${data.data.jenisKelamin} </p>
             <p> Kewarganegaraan : ${data.data.kewarganegaraan} </p>
             <p> Alamat : ${data.data.alamat} </p>
             <p> RtRw : ${data.data.rtRw} </p>
             <p> Kelurahan : ${data.data.kelurahan} </p>
             <p> Kecamatan : ${data.data.kecamatan} </p>
             </div> `;
                Swal.fire({
                    title: `Pakai Data Ocr Ktp Form ${+index + 1} ?`,
                    html: dataHtml,
                    confirmButtonText: "Pakai",
                    cancelButtonText: "Tidak",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    showCloseButton: true,
                    reverseButtons: true
                }).then(result => {
                    delete this.ocrResult[index];
                    if (result.value) {
                        this.bulkForm[index]["dataPasien"].nik = data.data.nik
                            ? +data.data.nik.trim().replaceAll(".", "")
                            : null;

                        this.bulkForm[index]["dataPasien"].namaDepan =
                            data.data.nama_depan;

                        this.bulkForm[index]["dataPasien"].namaBelakang =
                            data.data.nama_belakang;
                        let tglLahir = moment(
                            data.data.tgl_lahir,
                            "DD-MM-YYYY"
                        ).format("YYYY-MM-DD");

                        this.bulkForm[index]["dataPasien"].tglLahir = tglLahir;

                        // handle tanggal lahir
                        let jenisKelamin = null;
                        if (data.data.jenisKelamin) {
                            if (
                                data.data.jenisKelamin.toLowerCase() ==
                                "laki-laki"
                            ) {
                                jenisKelamin = "M";
                            } else if (
                                data.data.jenisKelamin.toLowerCase() ==
                                "perempuan"
                            ) {
                                jenisKelamin = "F";
                            }
                        }
                        this.bulkForm[
                            index
                        ].dataPasien.jenisKelamin = jenisKelamin;

                        // handle jenis kelamin

                        this.bulkForm[index]["dataPasien"].kewarganegaraan =
                            data.data.kewarganegaraan == "WNI"
                                ? "Indonesia"
                                : data.data.kewarganegaraan;
                        if (data.data.kewarganegaraan == "WNI") {
                            this.bulkForm[index]["dataPasien"].kewarganegaraan =
                                "Indonesia";
                        } else {
                            this.bulkForm[index]["dataPasien"].kewarganegaraan =
                                "WNA";
                            this.bulkForm[index]["dataPasien"].negara =
                                data.data.kewarganegaraan;
                        }

                        this.bulkForm[index]["dataPasien"].alamat =
                            data.data.alamat;

                        this.bulkForm[index]["dataPasien"].rtRw =
                            data.data.rtRw;
                        this.bulkForm[index][
                            "dataPasien"
                        ].rt = data.data.rtRw.substring(0, 3);
                        this.bulkForm[index][
                            "dataPasien"
                        ].rw = data.data.rtRw.substring(3, 6);

                        this.bulkForm[index]["dataPasien"].kelurahan =
                            data.data.kelurahan;

                        this.bulkForm[index]["dataPasien"].kecamatan =
                            data.data.kecamatan;
                        this.bulkForm[index]["dataPasien"].kabupaten =
                            data.data.kota;
                    }
                });
            }
        },
        async sha512(str) {
            return crypto.subtle
                .digest("SHA-512", new TextEncoder("utf-8").encode(str))
                .then(buf => {
                    return Array.prototype.map
                        .call(new Uint8Array(buf), x =>
                            ("00" + x.toString(16)).slice(-2)
                        )
                        .join("");
                });
        },
        async sendImage(index) {
            try {
                var vx = this;
                vx.ocrBeginLoading();
                vx.addImgKtpToForm(index);
                let fileName = this.fileName;
                const file = this.dataURLtoFile(this.cropImg, fileName);
                const blobURL = window.URL.createObjectURL(file);
                const img = new Image();
                img.src = blobURL;
                img.onload = async function(ev) {
                    window.URL.revokeObjectURL(blobURL);
                    const canvas = document.createElement("canvas");
                    canvas.width = 826;
                    canvas.height = 533;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, 826, 533);
                    canvas.toBlob(
                        function(blob) {
                            vx.blobToDataURL(blob, async b64 => {
                                let base64img = b64;
                                let type = `data:image/png;base64,`;
                                let dataUrl = base64img.replace(type, "");
                                base64img =
                                    `data:${vx.fileType};base64,` + dataUrl;
                                try {
                                    const time = moment().format();
                                    const hash = await vx.sha512(
                                        process.env.VUE_APP_OCR_CLIENT_KEY +
                                            "|" +
                                            moment(time).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            ) +
                                            "|" +
                                            base64img
                                    );
                                    const CancelToken = Axios.CancelToken;
                                    const source = CancelToken.source();
                                    vx.ocrResult[index] = {};
                                    vx.ocrResult[`source${index}`] = source;
                                    const data = await vx.postPatientOcr({
                                        data: { imagenya: base64img },
                                        headers: hash,
                                        time: time,
                                        source: source
                                    });
                                    delete vx.ocrResult[`source${index}`];
                                    vx.ocrDoneLoading();
                                    if (data.status == 200) {
                                        vx.ocrResult[index] = data.data;

                                        vx.applyOcrResult(index, data.data);
                                    }
                                } catch (error) {
                                    vx.ocrDoneLoading();
                                    console.log(error);
                                }
                            });
                        },
                        "image/jpg",
                        0.8
                    );
                };
            } catch (error) {
                console.log(error);
            }
        },
        DeleteImage() {
            this.imgSrc = null;
            this.croppedImgViewer = false;
            this.data = null;
            document.getElementById("ktp-ocr").value = null;
        },
        backToMenu() {
            this.setIsFormSave(false);
            this.setForm(false);
            this.setFormCount(0);
        },
        inputSsn(e) {
            const value = e.target.value;
            if (
                this.bulkForm[this.formPage - 1][`dataPasien`]
                    .kewarganegaraan == "Indonesia" ||
                this.bulkForm[this.formPage - 1][`dataPasien`]
                    .kewarganegaraan == null
            ) {
                if (String(value).length > 16) {
                    const newValue = String(
                        this.bulkForm[this.formPage - 1][`dataPasien`].nik
                    ).slice(0, 16);
                    this.bulkForm[this.formPage - 1][
                        `dataPasien`
                    ].nik = newValue;
                }
                let pattern = /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/;
                let valid = pattern.test(
                    this.bulkForm[this.formPage - 1][`dataPasien`].nik
                );
                this.isNikValid = valid;
            } else {
                this.isNikValid = true;
            }
        },
        createForm() {
            this.bulkForm = [];
            for (let i = 0; i < this.formCount; i++) {
                this.bulkForm.push({
                    isDeleted: false,
                    [`dataPasien`]: new formPasienModel(),
                    [`dataKlinis`]: new formDataKlinisPasienModel()
                });
            }
        },
        addForm() {
            this.bulkForm.push({
                isDeleted: false,
                [`dataPasien`]: new formPasienModel(),
                [`dataKlinis`]: new formDataKlinisPasienModel()
            });
            this.calFormCount("add");
        },
        deleteConfirmation(position) {
            var vx = this;
            new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Reservasi akan terhapus.",
                swalType: "warning",
                showCloseButton: true,
                onConfirmButton: function() {
                    vx.substractForm(position);
                }
            });
        },
        substractForm(pos) {
            this.bulkForm.splice(pos, 1);

            if (this.ocrResult[`source${pos}`]) {
                this.ocrResult[`source${pos}`].cancel(
                    "Operation canceled by the user."
                );
            }
            this.calFormCount("sub");
            if (this.bulkForm.length == 0) {
                this.backToMenu();
            } else {
                this.changePage(1);
            }
        },
        changePage(page, init) {
            if (!init) {
                this.DeleteImage();
                this.ocrDoneLoading();
            }
            if (page <= 0 || page > this.formCount) {
                return;
            }

            this.formPage = page;
            if (this.bulkForm[page]) {
                if (this.bulkForm[page].dataPasien) {
                    if (this.bulkForm[page].dataPasien.negara) {
                        this.bulkForm[page].dataPasien.kewarganegaraan == "WNA";
                    }
                }
            }
            if (!_.isEmpty(this.ocrResult)) {
                Object.keys(this.ocrResult).forEach(e => {
                    if (+e + 1 == page) {
                        this.applyOcrResult(e, this.ocrResult[e]);
                    }
                });
            }
            $("#" + this.modalName).modal("hide");
        },
        multipleForm() {
            this.isModalVisible = true;
            setTimeout(() => {
                $("#" + this.modalName).modal();
            }, 50);
        },
        setImage(e) {
            this.cropImg = null;
            this.imgSrc = null;
            this.setIsCropperModal(false);
            const file = e.target.files[0];
            this.fileType = file.type;
            this.fileName = file.name;
            if (file.type.indexOf("image/") === -1) {
                alert("Please select an image file");
                return;
            }
            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = event => {
                    this.imgSrc = event.target.result;
                    document.getElementById("ktp-ocr").value = null;
                    // rebuild cropperjs with the updated source
                    // this.$refs.cropper.replace(event.target.result);

                    this.setIsCropperModal(true);
                };
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        blobToDataURL(blob, callback) {
            var a = new FileReader();
            a.onload = function(e) {
                callback(e.target.result);
            };
            a.readAsDataURL(blob);
        },
        saveConfirmation() {
            let pattern = /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/;
            let emailPat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            var vx = this;
            this.validationInput = {};
            let notIncluded = [
                "rtRw",
                "negara",
                "rt",
                "rw",
                "linkFoto",
                "imgFoto",
                "namaBelakang"
            ];
            this.bulkForm.forEach((el, index) => {
                el.dataPasien.namaDepan = el.dataPasien.namaDepan
                    ? el.dataPasien.namaDepan.replace(/\w\S*/g, function(txt) {
                          return (
                              txt.charAt(0).toUpperCase() +
                              txt.substr(1).toLowerCase()
                          );
                      })
                    : null;
                el.dataPasien.namaBelakang = el.dataPasien.namaBelakang
                    ? el.dataPasien.namaBelakang.replace(/\w\S*/g, function(
                          txt
                      ) {
                          return (
                              txt.charAt(0).toUpperCase() +
                              txt.substr(1).toLowerCase()
                          );
                      })
                    : null;
                for (var key in el.dataPasien) {
                    if (!notIncluded.includes(key)) {
                        if (
                            el.dataPasien[key] == null ||
                            el.dataPasien[key] == ""
                        ) {
                            if (!this.validationInput[index + 1]) {
                                this.validationInput[index + 1] = [];
                            }
                            if (key == "imgKtp") {
                                this.validationInput[index + 1].push(
                                    `Foto KTP/Kitas/Passport Di Butuhkan`
                                );
                            } else if (key !== "linkKtp") {
                                this.validationInput[index + 1].push(
                                    `${this.$t(`common.${[key]}`)} Di Butuhkan`
                                );
                            }
                        }
                    }
                }
                if (el.dataPasien.nik) {
                    if (
                        el.dataPasien.kewarganegaraan == "Indonesia" ||
                        el.dataPasien.kewarganegaraan == null
                    ) {
                        this.isNikValid = pattern.test(el.dataPasien.nik);
                    }
                }
                if (el.dataPasien.email) {
                    this.isEmailValid = emailPat.test(el.dataPasien.email);
                }
                if (!this.isNikValid) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push("NIK Tidak Valid"); // );
                }
                if (!this.isEmailValid) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push("Email Tidak Valid");
                }
                if (!this.isNoTelpValid) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push(
                        "No. Telepon Tidak Valid"
                    );
                }
                if (el.dataKlinis.tipe_test.length == 0) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push(
                        "Tipe Test Di Butuhkan"
                    );
                }

                if (el.dataKlinis.gejala) {
                    if (!el.dataKlinis.gejalaDetail) {
                        if (!this.validationInput[index + 1]) {
                            this.validationInput[index + 1] = [];
                        }
                        this.validationInput[index + 1].push(
                            "Gejala Detail Di Butuhkan"
                        );
                    }
                    if (!el.dataKlinis.tglAwalGejala) {
                        if (!this.validationInput[index + 1]) {
                            this.validationInput[index + 1] = [];
                        }
                        this.validationInput[index + 1].push(
                            "Tanggal Awal Gejala Di Butuhkan"
                        );
                    }
                } else if (el.dataKlinis.gejala === null) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push("Gejala Di Butuhkan");
                }

                // sampaisini
                if (el.dataKlinis.riwayatKontak == "ADA") {
                    if (!el.dataKlinis.riwayatKontakDetail) {
                        if (!this.validationInput[index + 1]) {
                            this.validationInput[index + 1] = [];
                        }
                        this.validationInput[index + 1].push(
                            "Riwayat Kontak Detail Di Butuhkan"
                        );
                    }
                } else if (!el.dataKlinis.riwayatKontak) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push(
                        "Riwayat Kontak Di Butuhkan"
                    );
                }
                if (!el.dataKlinis.tujuanPemeriksaan) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push(
                        "Tujuan Pemeriksaan Di Butuhkan"
                    );
                }

                if (!el.dataKlinis.riwayatPerjalanan) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push(
                        "Riwayat Perjalanan Di Butuhkan"
                    );
                }

                if (el.dataKlinis.penyakitPenyerta === null) {
                    if (!this.validationInput[index + 1]) {
                        this.validationInput[index + 1] = [];
                    }
                    this.validationInput[index + 1].push(
                        "Penyakit Penyerta Di Butuhkan"
                    );
                } else if (el.dataKlinis.penyakitPenyerta) {
                    if (!el.dataKlinis.penyakitPenyertaDetail) {
                        if (!this.validationInput[index + 1]) {
                            this.validationInput[index + 1] = [];
                        }
                        this.validationInput[index + 1].push(
                            "Detail Penyakit Penyerta Di Butuhkan"
                        );
                    }
                }

                if (el.dataKlinis.riwayatKontak == "ADA") {
                    if (!el.dataKlinis.riwayatKontakDetail) {
                        if (!this.validationInput[index + 1]) {
                            this.validationInput[index + 1] = [];
                        }
                        this.validationInput[index + 1].push(
                            "Riwayat Kontak Detail Di Butuhkan"
                        );
                    }
                }
                if (el.dataPasien.kewarganegaraan == "WNA") {
                    if (el.dataPasien.negara !== null) {
                        if (el.dataPasien.negara.name) {
                            el.dataPasien.kewarganegaraan =
                                el.dataPasien.negara.name;
                        } else {
                            if (!this.validationInput[index + 1]) {
                                this.validationInput[index + 1] = [];
                            }
                            this.validationInput[index + 1].push(
                                "Negara Di Butuhkan"
                            );
                        }
                    } else if (el.dataPasien.negara == null) {
                        if (!this.validationInput[index + 1]) {
                            this.validationInput[index + 1] = [];
                        }
                        this.validationInput[index + 1].push(
                            "Negara Di Butuhkan"
                        );
                    }
                }
                if (el.dataPasien.tglLahir) {
                    el.dataPasien.tglLahir = moment(
                        el.dataPasien.tglLahir
                    ).format("YYYY-MM-DD");
                }
            });
            if (!_.isEmpty(this.validationInput)) {
                this.setIsShowRequiredModal(false);
                setTimeout(() => {
                    this.setIsShowRequiredModal(true);
                }, 50);
            } else {
                new SweetAlert().showConfirm({
                    swalTitle: "Data Pasien Akan Tersimpan",
                    swalText: "Pastikan Data Yang Diisi Sudah Benar",
                    swalType: "warning",
                    onConfirmButton: function() {
                        vx.save();
                    },
                    onCancelButton: function() {
                        // vx.reservationVue.isAuthRefreshState = true;
                    }
                });
            }
        },
        save() {
            this.setPatientForms(this.bulkForm);
            this.setForm(false);
            this.setIsFormSave(true);
            localStorage.setItem("bulkForm", JSON.stringify(this.bulkForm));
            Object.keys(this.ocrResult).forEach((e, index) => {
                if (isNaN(e)) {
                    if (this.ocrResult[e]) {
                        this.ocrResult[e].cancel();
                    }
                }
            });
        },
        addImgKtpToForm(index) {
            this.bulkForm[index].dataPasien.imgKtp = this.fileName;
            let type = `data:${this.fileType};base64,`;
            let dataUrl = this.cropImg.replace(type, "");
            this.bulkForm[index].dataPasien.linkKtp = dataUrl;
        }
    },
    created() {
        this.eventCalendarChange();
        this.getDefaultRangeDate();
        this.getPaket();
        this.getNations();
        this.negaraOptions = this.nations;

        if (localStorage.getItem("bulkForm")) {
            this.bulkForm = JSON.parse(localStorage.getItem("bulkForm"));
            this.setFormCount(this.bulkForm.length);
            this.changePage(1, true);
        } else {
            this.createForm();
            this.changePage(1, true);
        }
    },
    mounted() {
        var elts = document.getElementsByClassName("rt-rw");
        Array.from(elts).forEach(function(elt) {
            elt.addEventListener("keyup", function(event) {
                if (elt.value.length == 3) {
                    if (elt.nextElementSibling) {
                        elt.nextElementSibling.focus();
                    }
                }
            });
        });
    },
    destroyed() {
        this.setIsShowRequiredModal(false);
    }
};
</script>
