<template>
    <div class="modal fade" :id="modalName">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div
                class="modal-content"
                style="width: 103% !important;display: flex;align-items: center;"
                id="modal-cropper"
            >
                <div v-if="imgSrc || cropImg" class="cropper-area">
                    <div class="img-cropper">
                        <vue-cropper
                            id="ktp-cropper"
                            :min-container-width="contStyle.width"
                            :min-container-height="400"
                            v-if="imgSrc && !cropImg"
                            ref="cropper"
                            :src="imgSrc"
                            alt="Source Image"
                            :containerStyle="contStyle"
                            :aspect-ratio="826 / 533"
                            :crop-box-movable="false"
                            :crop-box-resizable="false"
                            drag-mode="move"
                            :view-mode="1"
                            :movable="true"
                            preview=".preview"
                        >
                        </vue-cropper>
                        <button
                            v-if="imgSrc && !cropImg"
                            class=" crop-btn btn abs-btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                            @click.prevent="cropImage"
                        >
                            Crop
                        </button>
                        <button
                            v-if="imgSrc && !cropImg"
                            style="left: 5px;"
                            class="btn abs-btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                            @click.prevent="DeleteImage"
                        >
                            cancel
                        </button>
                        <!-- <div>
                            <section class="preview-area">
                                <p>Preview</p>
                                <div class="preview"></div>
                            </section>
                        </div> -->
                    </div>

                    <div class="cropper-btns" v-if="imgSrc && !cropImg">
                        <button
                            class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                            role="button"
                            @click.prevent="rotate(90)"
                        >
                            Rotate +90deg
                        </button>
                        <button
                            class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                            role="button"
                            @click.prevent="reset"
                        >
                            Reset
                        </button>
                        <button
                            class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                            role="button"
                            @click.prevent="rotate(-90)"
                        >
                            Rotate -90deg
                        </button>
                        <!-- <button
                            class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                            @click.prevent="cropImage"
                        >
                            Crop
                        </button>
                        <button
                            class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                            @click.prevent="reset"
                        >
                            Reset
                        </button> -->
                    </div>
                    <div class="cropped-image ocr">
                        <div
                            style="margin-top: 15px;
    margin-bottom: 15px;"
                        >
                            <img
                                :style="
                                    ocrLoading ? 'filter: grayscale(100%);' : ''
                                "
                                v-if="croppedImgViewer"
                                :src="cropImg"
                                alt="Cropped Image"
                                class="cropped-image-img"
                            />
                            <i
                                v-if="ocrLoading"
                                class="fas fa-spinner fa-spin fa-fw cropped-image-loading"
                            ></i>
                        </div>
                        <div
                            style="display: flex;
    justify-content: space-around;"
                        >
                            <button
                                v-if="croppedImgViewer"
                                :disabled="ocrLoading"
                                class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                                @click.prevent="DeleteImage"
                            >
                                Cancel Scan KTP
                            </button>
                            <button
                                v-if="croppedImgViewer"
                                :disabled="ocrLoading"
                                class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                                @click.prevent="addImgKtpToForm(formPage - 1)"
                            >
                                Scan & Save KTP
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    components: {
        VueCropper
    },
    props: {
        modalName: { type: String, default: "cropperModal" },
        imgSrc: { type: String },
        // cropImg: { type: String },
        fileType: { type: String },
        fileName: { type: String },
        formPage: { type: Number }
    },
    mounted() {
        $("#" + this.modalName).modal();
    },
    data() {
        return {
            cropImg: null,
            // fileType: null,
            // fileName: null,
            // imgSrc: null,
            ocrLoading: false,
            contStyle: {
                width: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
                    ? 400
                    : screen.availWidth < 1920
                    ? 700
                    : 1200,
                height: 500
            },
            croppedImgViewer: false,
            emitData: {
                formIndex: null,
                imgKtp: null,
                linkKtp: null,
                dataUri: null,
                fileType: null
            }
        };
    },
    destroyed() {
        $("#" + this.modalName).modal("hide");
    },
    methods: {
        ...mapActions("DriveThruStore", {
            postPatientOcr: "postPatientOcr"
        }),
        ...mapMutations("DriveThruStore", {
            setIsCropperModal: "SET_IS_CROPPER_MODAL"
        }),
        setImage(e) {
            this.cropImg = null;
            // this.imagePreview(false);
            const file = e.target.files[0];
            this.fileType = file.type;
            this.fileName = file.name;
            if (file.type.indexOf("image/") === -1) {
                alert("Please select an image file");
                return;
            }
            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = event => {
                    this.imgSrc = event.target.result;
                    // rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result);
                };
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        // imagePreview(payload) {
        //     this.isPreviewImag = payload;
        // },
        reset() {
            this.$refs.cropper.reset();
        },
        DeleteImage() {
            // this.imgSrc = null;
            // this.croppedImgViewer = false;
            // this.data = null;
            $("#" + this.modalName).modal("hide");
            this.setIsCropperModal(false);
            document.getElementById("ktp-ocr").value = null;
        },
        addImgKtpToForm(index) {
            // this.bulkForm[index].dataPasien.imgKtp = this.fileName;
            let type = `data:${this.fileType};base64,`;
            let base64ImageKtp = this.cropImg.replace(type, "");
            // this.bulkForm[index].dataPasien.linkKtp = base64ImageKtp;
            this.emitData.imgKtp = this.fileName;
            this.emitData.linkKtp = base64ImageKtp; //image nya
            this.emitData.formIndex = index;
            this.emitData.dataUri = type;
            this.emitData.fileType = this.fileType;
            this.emitDataMet(this.emitData);
        },

        cropImage() {
            this.croppedImgViewer = true;
            this.cropImg = this.$refs.cropper
                .getCroppedCanvas()
                .toDataURL(this.fileType, 0.9);
            this.preViewImg = this.$refs.cropper
                .getCroppedCanvas()
                .toDataURL(this.fileType, 0.9);
            // this.unRenderCropper();
        },

        emitDataMet(data) {
            this.$emit("update:changedValue", data);
        }
    }
};
</script>

<style></style>
