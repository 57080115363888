export default function Fee() {
    return {
        gejala: false, // bool
        tglAwalGejala: null, // date
        gejalaDetail: null,
        riwayatKontak: "TIDAK_ADA",
        riwayatKontakDetail: null,
        penyakitPenyerta: false, //bool
        penyakitPenyertaDetail: null,
        riwayatPerjalanan: "TIDAK_ADA",
        tipe_test: [],
        tujuanPemeriksaan: "SCREENING"
    };
}
