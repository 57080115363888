<template>
    <div style="position:relative;">
        <p class="error">{{ error }}</p>
        <!-- <p>{{ result }}</p> -->
        <button
            class="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
            style="position:absolute;top:0;z-index:5;"
            @click="setQrCodeScan(false)"
        >
            BACK
        </button>
        <div
            :class="{ fullscreen: fullscreen }"
            class="scanner-container"
            ref="wrapper"
            style="display: flex;"
            @fullscreenchange="onFullscreenChange"
        >
            <qrcode-stream
                :camera="camera"
                :track="selected.value"
                @decode="onDecode"
                @init="onInit"
                class="qr-code-scanner"
                v-if="!isQrDone"
            >
                <button
                    @click="fullscreen = !fullscreen"
                    class="fullscreen-button"
                >
                    <i class="fas fa-expand"></i>
                </button>
                <div v-if="validationPending" class="validation-pending">
                    Loading...
                </div>
            </qrcode-stream>

            <div style="height: 85vh;text-align:center;" v-else>
                <h1>Loading QrCode</h1>
            </div>
            <div class="qr-text">
                <h3>Scan disini untuk QR Scanner/Kamera Tambahan</h3>
                <textarea
                    ref="textScanner"
                    v-model="textScan"
                    style="width:90%;"
                    rows="10"
                ></textarea>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapActions, mapMutations } from "vuex";
import BlockUI from "../../components/_js/BlockUI";
const blockUI = new BlockUI();

import Axios from "axios";
export default {
    components: {
        QrcodeStream
    },
    data() {
        const options = [
            { text: "bounding box", value: this.paintBoundingBox }
        ];
        const selected = options[0];
        var vx = this;
        return {
            result: "",
            error: "",
            selected,
            options,
            isQrDone: false,
            fullscreen: false,
            camera: "auto",
            isValid: false,
            textScan: ""
        };
    },
    watch: {
        fullscreen(enterFullscreen) {
            if (enterFullscreen) {
                this.requestFullscreen();
            } else {
                this.exitFullscreen();
            }
        },
        textScan(val) {
            this.textDecode(val);
        }
    },
    computed: {
        validationPending() {
            return this.isValid === false && this.camera === "off";
        },
        validationSuccess() {
            return this.isValid === true;
        },

        validationFailure() {
            return this.isValid === false;
        }
    },
    methods: {
        ...mapActions("DriveThruStore", {
            getOrderId: "getOrderId",
            getQrCode: "getQrCode"
        }),
        ...mapMutations("DriveThruStore", {
            setQrCodeScan: "SET_QR_CODE_SCAN"
        }),
        onFullscreenChange(event) {
            this.fullscreen = document.fullscreenElement !== null;
        },
        textDecode: _.debounce(function(string) {
            this.onDecode(string);
        }, 1000),
        requestFullscreen() {
            const elem = this.$refs.wrapper;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        },
        exitFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        },
        logErrors(promise) {
            promise.catch(console.error);
        },
        async onDecode(result) {
            this.result = result;
            const baseUrl = process.env.VUE_APP_API_URL;
            try {
                var urls = new URL(result);
                if (urls.origin == process.env.VUE_APP_API_URL) {
                    const path = urls.pathname.replace("/gatewayexternal", "");
                    const params = urls.search;
                    const validUrl = `${baseUrl}${path}${params}`;
                    //ubah base url jadi pake origin dari url yang di decode
                    try {
                        this.camera = "off";
                        const data = await this.getOrderId(validUrl);
                        this.camera = "auto";
                        this.isValid = true;
                        this.$router.push({
                            name: "drive-thru-view",
                            params: { id: data.data.orderId }
                        });
                    } catch (error) {
                        this.camera = "auto";
                        console.log(error);
                    }
                } else {
                    swal.fire({
                        title: "QrCode tidak valid",
                        text: this.textScan,
                        type: "error",
                        confirmButtonText: "OK"
                    });
                    this.textScan = "";
                    this.error = "QR Code Tidak Valid";
                }
            } catch (error) {
                console.log("error", error);
                this.error = error;
            }
        },
        async onInit(promise) {
            try {
                await promise;
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    this.error =
                        "ERROR: you need to grant camera access permission";
                } else if (error.name === "NotFoundError") {
                    this.error = "ERROR: no camera on this device";
                } else if (error.name === "NotSupportedError") {
                    this.error =
                        "ERROR: secure context required (HTTPS, localhost)";
                } else if (error.name === "NotReadableError") {
                    this.error = "ERROR: is the camera already in use?";
                } else if (error.name === "OverconstrainedError") {
                    this.error = "ERROR: installed cameras are not suitable";
                } else if (error.name === "StreamApiNotSupportedError") {
                    this.error =
                        "ERROR: Stream API is not supported in this browser";
                } else if (error.name === "InsecureContextError") {
                    this.error =
                        "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
                } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        },
        paintBoundingBox(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const {
                    boundingBox: { x, y, width, height }
                } = detectedCode;

                ctx.lineWidth = 2;
                ctx.strokeStyle = "#007bff";
                ctx.strokeRect(x, y, width, height);
            }
        },
        logErrors(promise) {
            promise.catch(console.error);
        }
    },
    mounted() {
        this.$refs.textScanner.focus();
    }
};
</script>
